import React from 'react';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import { IoLocationOutline } from 'react-icons/io5';
import ImgYeni from '../assets/img/testimonial/Yeni Wulandari.jpg';
import ImgSiti from '../assets/img/testimonial/Siti.jpg';
import ImgEndry from '../assets/img/testimonial/Endry.jpg';
import ImgSusan from '../assets/img/testimonial/Susan.jpg';
import ImgIndriani from '../assets/img/testimonial/indriani.jpg';
import ImgWinarty from '../assets/img/testimonial/Winarty.jpg';

function Testimonial() {
    const state = {
        selectedFooter: 1,
    };

    const { t } = useTranslation(); // Menggunakan useTranslation hook

    return (
        <section
            className="relative w-full bg-[#19191A] px-[20px] py-[46px] lg:px-[112px] lg:py-[75px]"
            id="testimonial">
            <div className="mb-8 text-center text-[#F5F5F5] lg:mb-[32px]">
                <h2
                    className="mb-[8px] text-headline-lg lg:mb-[16px]"
                    data-aos="fade-up"
                    data-aos-offset="10"
                    data-aos-delay="10"
                    data-aos-duration="1000">
                    {t('testimonials.title')}
                </h2>
                <p
                    className="lg:text-headline-sm lg:font-light"
                    data-aos="fade-up"
                    data-aos-offset="10"
                    data-aos-delay="10"
                    data-aos-duration="1000">
                    {t('testimonials.description')}
                </p>
            </div>

            <div className="flex flex-col justify-center gap-6">
                {/* Row/Col 1 */}
                <div className="flex flex-col justify-center gap-6 text-white lg:flex-row">
                    <div
                        className="flex flex-col rounded-3xl bg-[#202022] p-4 shadow-lg lg:w-[25%]"
                        data-aos="fade-up"
                        data-aos-offset="10"
                        data-aos-delay="10"
                        data-aos-duration="1000">
                        <div className="mb-[16px] flex flex-row gap-4">
                            <div className="">
                                <img
                                    src={ImgIndriani}
                                    alt=""
                                    width="60"
                                    height="60"
                                    className="h-[60px] w-[60px] rounded-[8px] object-cover"
                                />
                            </div>

                            <div>
                                <h1 className="mb-2 text-body-lg font-semibold text-white">
                                    {t('testimonials.indriyani.nama')}
                                </h1>
                                <p className="text-subtitle-sm font-normal text-white">
                                    - {t('testimonials.indriyani.position')}{' '}
                                </p>
                            </div>
                        </div>
                        <p className="mb-auto text-subtitle-sm font-normal text-white">
                            {t('testimonials.indriyani.description')}
                        </p>
                        <div className="mt-4 flex items-center gap-2 text-subtitle-sm font-normal text-white">
                            <IoLocationOutline /> <span>Singapura</span>
                        </div>
                    </div>

                    <div
                        className="flex flex-col rounded-3xl bg-[#C9A254] p-4 shadow-lg lg:w-[40%]"
                        data-aos="fade-up"
                        data-aos-offset="10"
                        data-aos-delay="10"
                        data-aos-duration="1000">
                        <div className="mb-[16px] flex flex-row gap-4">
                            <div className="">
                                <img
                                    src={ImgWinarty}
                                    alt=""
                                    width="60"
                                    height="60"
                                    className="h-[60px] w-[60px] rounded-[8px] object-cover"
                                />
                            </div>

                            <div>
                                <h1 className="mb-2 text-body-lg font-semibold text-white">
                                    {t('testimonials.winarty.nama')}
                                </h1>
                                <p className="text-subtitle-sm font-normal text-white">
                                    - {t('testimonials.winarty.position')}{' '}
                                </p>
                            </div>
                        </div>
                        <p className="mb-auto text-subtitle-sm font-normal text-white">
                            {t('testimonials.winarty.description')}
                        </p>
                        <div className="mt-4 flex items-center gap-2 text-subtitle-sm font-normal text-white">
                            <IoLocationOutline /> <span>Singapura</span>
                        </div>
                    </div>

                    <div
                        className="flex flex-col rounded-3xl bg-[#202022] p-4 shadow-lg lg:w-[35%]"
                        data-aos="fade-up"
                        data-aos-offset="10"
                        data-aos-delay="10"
                        data-aos-duration="1000">
                        <div className="mb-[16px] flex flex-row gap-4">
                            <div className="">
                                <img
                                    src={ImgYeni}
                                    alt=""
                                    width="60"
                                    height="60"
                                    className="h-[60px] w-[60px] rounded-[8px] object-cover"
                                />
                            </div>

                            <div>
                                <h1 className="mb-2 text-body-lg font-semibold text-white">
                                    {t('testimonials.yeni.nama')}
                                </h1>
                                <p className="text-subtitle-sm font-normal text-white">
                                    - {t('testimonials.yeni.position')}{' '}
                                </p>
                            </div>
                        </div>
                        <p className="mb-auto text-subtitle-sm font-normal text-white">
                            {t('testimonials.yeni.description')}
                        </p>
                        <div className="mt-4 flex items-center gap-2 text-subtitle-sm font-normal text-white">
                            <IoLocationOutline /> <span>Singapura</span>
                        </div>
                    </div>
                </div>

                {/* Row/Col 2 */}
                <div className="flex flex-col justify-center gap-6 text-white lg:flex-row">
                    <div
                        className="flex flex-col rounded-3xl bg-[#C9A254] p-4 shadow-lg lg:w-[40%]"
                        data-aos="fade-up"
                        data-aos-offset="10"
                        data-aos-delay="10"
                        data-aos-duration="1000">
                        <div className="mb-[16px] flex flex-row gap-4">
                            <div className="">
                                <img
                                    src={ImgEndry}
                                    alt=""
                                    width="60"
                                    height="60"
                                    className="h-[60px] w-[60px] rounded-[8px] object-cover"
                                />
                            </div>

                            <div>
                                <h1 className="mb-2 text-body-lg font-semibold text-white">
                                    {t('testimonials.endry.nama')}
                                </h1>
                                <p className="text-subtitle-sm font-normal text-white">
                                    - {t('testimonials.endry.position')}{' '}
                                </p>
                            </div>
                        </div>
                        <p className="mb-auto text-subtitle-sm font-normal text-white">
                            {t('testimonials.endry.description')}
                        </p>
                        <div className="mt-4 flex items-center gap-2 text-subtitle-sm font-normal text-white">
                            <IoLocationOutline /> <span>Singapura</span>
                        </div>
                    </div>

                    <div
                        className="flex flex-col rounded-3xl bg-[#202022] p-4 shadow-lg lg:w-[35%]"
                        data-aos="fade-up"
                        data-aos-offset="10"
                        data-aos-delay="10"
                        data-aos-duration="1000">
                        <div className="mb-[16px] flex flex-row gap-4">
                            <div className="">
                                <img
                                    src={ImgSiti}
                                    alt=""
                                    width="60"
                                    height="60"
                                    className="h-[60px] w-[60px] rounded-[8px] object-cover"
                                />
                            </div>

                            <div>
                                <h1 className="mb-2 text-body-lg font-semibold text-white">
                                    {t('testimonials.siti.nama')}
                                </h1>
                                <p className="text-subtitle-sm font-normal text-white">
                                    - {t('testimonials.siti.position')}{' '}
                                </p>
                            </div>
                        </div>
                        <p className="mb-auto text-subtitle-sm font-normal text-white">
                            {t('testimonials.siti.description')}
                        </p>
                        <div className="mt-4 flex items-center gap-2 text-subtitle-sm font-normal text-white">
                            <IoLocationOutline /> <span>Singapura</span>
                        </div>
                    </div>

                    <div
                        className="flex flex-col rounded-3xl bg-[#C9A254] p-4 shadow-lg lg:w-[25%]"
                        data-aos="fade-up"
                        data-aos-offset="10"
                        data-aos-delay="10"
                        data-aos-duration="1000">
                        <div className="mb-[16px] flex flex-row gap-4">
                            <div className="">
                                <img
                                    src={ImgSusan}
                                    alt=""
                                    width="60"
                                    height="60"
                                    className="h-[60px] w-[60px] rounded-[8px] object-cover"
                                />
                            </div>

                            <div>
                                <h1 className="mb-2 text-body-lg font-semibold text-white">
                                    {t('testimonials.susan.nama')}
                                </h1>
                                <p className="text-subtitle-sm font-normal text-white">
                                    - {t('testimonials.susan.position')}{' '}
                                </p>
                            </div>
                        </div>
                        <p className="mb-auto text-subtitle-sm font-normal text-white">
                            {t('testimonials.susan.description')}
                        </p>
                        <div className="mt-4 flex items-center gap-2 text-subtitle-sm font-normal text-white">
                            <IoLocationOutline /> <span>Singapura</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;
