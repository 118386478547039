import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import debounce from 'lodash.debounce';
import { baseApi } from '../utils/constant';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import 'aos/dist/aos.css';
import Howit3 from '../assets/howit3.jpg';

function Blog() {
    const [blogs, setBlogs] = useState([]);
    const [page, setPage] = useState();
    const [limit, setLimit] = useState(3);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [keyword, setKeyword] = useState('');
    const [query, setQuery] = useState('');
    const [msg, setMsg] = useState('');
    const [expired, setExpired] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        getBlogs();
    }, [page, keyword, limit]);

    const getBlogs = async () => {
        try {
            const response = await axios.get(
                `${baseApi}/blog/blogsList?page=${page}&limit=${limit}`,
            );
            setBlogs(response.data.result);
            setPage(response.data.page);
            setPages(response.data.totalPage);
            setRows(response.data.totalRows);

            if (response.data.result.length === 0) {
                setMsg('Data yang anda cari tidak di temukan.');
            } else {
                setMsg('');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setMsg('Terjadi kesalahan saat mengambil data.');
        }
    };

    return (
        <section
            className="relative w-full bg-[#19191A] px-[20px] py-[46px] text-[#F5F5F5] lg:px-[112px] lg:py-[75px]"
            id="blog">
            <div
                className="mb-2 text-center lg:mb-[32px]"
                data-aos="fade-up"
                data-aos-offset="100"
                data-aos-delay="10"
                data-aos-duration="1000">
                <h2 className="text-headline-lg">#Blog</h2>
            </div>

            <div
                className="parent-section mb-[42px] px-[] py-[24px] lg:mb-[24px]"
                data-aos="fade-up"
                data-aos-offset="100"
                data-aos-delay="10"
                data-aos-duration="2000">
                <div className="flex flex-col md:items-center lg:flex-row">
                    <div className="md:w-full">
                        <img
                            className="min-w-sm mb-[18px] h-[300px] rounded-[18px] object-cover shadow-lg md:w-full lg:mb-0 lg:w-[400px]"
                            src={Howit3}
                            alt=""
                            width="400"
                            height="400"
                        />
                    </div>
                    <div className="card-details m-auto flex flex-col justify-center lg:ml-[70px]">
                        <div className="mb-2 text-[20px] font-bold lg:mb-4 lg:text-headline-lg lg:font-bold">
                            Tips for Employers: How to Build a Positive Working
                            Relationship with Your Maid
                        </div>
                        <div className="mb-4 text-subtitle-lg font-light text-[#BFBFBF] lg:mb-4 lg:font-light">
                            At Supplier Helper, we celebrate the transformative
                            journeys of many Indonesian maids who have found
                            fulfilling employment in Singapore. Through our
                            comprehensive training and meticulous matching
                            process, these dedicated individuals gain essential
                            skills and are placed with supportive employers,
                            creating harmonious working relationships.
                        </div>
                        <div className="bottom">
                            <Link
                                to="/blogList"
                                className="rounded-md bg-[#F3B129] px-4 py-2 text-button-lg hover:bg-[#c58d1f] lg:bg-[#F3B129] lg:px-4"
                                data-aos="zoom-in"
                                data-aos-duration="1000">
                                Read More
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-8 flex lg:mb-[32px]">
                <h1
                    className="text-headline-md"
                    data-aos="fade-up"
                    data-aos-offset="100"
                    data-aos-delay="10"
                    data-aos-duration="2000">
                    Latest blog post
                </h1>
            </div>

            <div
                className="grid grid-cols-1 gap-10 lg:grid-cols-3"
                data-aos="fade-up"
                data-aos-offset="100"
                data-aos-delay="10"
                data-aos-duration="2000">
                {blogs.length > 0 ? (
                    blogs.map((blog) => (
                        <div
                            key={blog.id}
                            className="relative grid min-h-[30rem] flex-col items-end overflow-hidden rounded-xl bg-transparent bg-clip-border text-[#313131] shadow-md">
                            <div>
                                <img
                                    className="absolute inset-0 h-full w-full object-cover object-top"
                                    src={blog.url} // Gambar default atau fallback
                                    alt=""
                                    width="800"
                                    height="600"
                                />
                                <div className="absolute inset-0 top-[180px] h-[302px] bg-gradient-transparent"></div>
                                <div className="relative flex flex-col justify-end p-6">
                                    <h1 className="text-2xl block text-body-lg font-extrabold text-[#19191A] antialiased">
                                        {blog.title}
                                    </h1>
                                    <p className="my-2 line-clamp-3 h-[60px] text-body-sm font-normal text-[#202022] antialiased">
                                        {blog.content}
                                    </p>
                                    <Link
                                        to={`/blogList/detail/${blog.id}`}
                                        className="my-2 block w-fit rounded-lg bg-[#19191A] p-2 text-button-lg font-bold leading-relaxed text-[#F5F5F5] antialiased hover:bg-[#202022]">
                                        READ MORE
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="content-center text-center">
                        <p
                            colSpan="8"
                            className="px-4 py-2 text-center text-white">
                            {msg || 'Tidak ada data'}
                        </p>
                    </div>
                )}
            </div>
        </section>
    );
}

export default Blog;
