import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { baseApi } from '../../utils/constant';

import defaultImage from '../../assets/default_profile.png';

function EditHelpers() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [expired, setExpired] = useState(0);
    const [token, setToken] = useState('');
    const [file, setFile] = useState('');
    const [preview, setPreview] = useState('');
    const [nama, setNama] = useState('');
    const [alamat, setAlamat] = useState('');
    const [negara, setNegara] = useState('');
    const [tanggalLahir, setTanggalLahir] = useState('');
    const [agama, setAgama] = useState('');
    const [jenisKelamin, setJenisKelamin] = useState('Perempuan');
    const [noHp, setNoHp] = useState('');
    const [pendidikan, setPendidikan] = useState('');
    const [tinggiBadan, setTinggiBadan] = useState('');
    const [beratBadan, setBeratBadan] = useState('');
    const [statusPernikahan, setStatusPernikahan] = useState('');
    const [jumlahSaudara, setJumlahSaudara] = useState('');
    const [jumlahAnak, setJumlahAnak] = useState('');
    const [alergi, setAlergi] = useState('');
    const [tato, setTato] = useState('');
    const [kemampuanBahasa, setKemampuanBahasa] = useState('');
    const [kemampuanMasak, setKemampuanMasak] = useState('');
    const [namaBandara, setNamaBandara] = useState('');
    const [pengalaman, setPengalaman] = useState('');
    const [deskripsi, setDeskripsi] = useState('');
    const [sponsor, setSponsor] = useState('');
    const [status, setStatus] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);

    // const loadImage = (e) => {
    //     const image = e.target.files[0];
    //     setFile(image);
    //     setPreview(URL.createObjectURL(image));
    // };

    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            await refreshToken();
            await getHelperById(id);
        };
        fetchData();
    }, [id]);

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseApi}/users/token`);
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setUsername(decoded.username);
            setEmail(decoded.email);
            setExpired(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate('/login');
            }
        }
    };

    const axiosJwt = axios.create();

    axiosJwt.interceptors.request.use(
        async (config) => {
            const currentDate = new Date();
            if (expired < currentDate.getTime() / 1000) {
                const response = await axios.get(`${baseApi}/users/token`);
                config.headers.Authorization = `Bearer ${response.data.accessToken}`;
                setToken(response.data.accessToken);
                const decoded = jwtDecode(response.data.accessToken);
                setUsername(decoded.username);
                setEmail(decoded.email);
                setExpired(decoded.exp);
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        },
    );

    const getHelperById = async (id) => {
        const response = await axiosJwt.get(`${baseApi}/helper/helpers/${id}`);
        setNama(response.data.nama);
        setAlamat(response.data.alamat);
        setTanggalLahir(response.data.tanggal_lahir);
        setAgama(response.data.agama);
        setNegara(response.data.negara);
        setJenisKelamin(response.data.jenis_kelamin);
        setNoHp(response.data.no_hp);
        setPendidikan(response.data.pendidikan);
        setTinggiBadan(response.data.tinggi_badan);
        setBeratBadan(response.data.berat_badan);
        setStatusPernikahan(response.data.status_pernikahan);
        setJumlahSaudara(response.data.jumlah_saudara);
        setJumlahAnak(response.data.jumlah_anak);
        setAlergi(response.data.alergi);
        setTato(response.data.tato);
        setKemampuanBahasa(response.data.kemampuan_bahasa);
        setKemampuanMasak(response.data.kemampuan_masak);
        setNamaBandara(response.data.nama_bandara);
        setPengalaman(response.data.pengalaman);
        setDeskripsi(response.data.deskripsi);
        setSponsor(response.data.sponsor);
        setStatus(response.data.status);
        setPreview(response.data.url);
    };

    const loadImage = (e) => {
        const newFile = e.target.files[0];
        if (newFile) {
            setFile(newFile);
            setPreview(URL.createObjectURL(newFile));
        } else {
            setFile(null);
        }
    };

    const updateHelpers = async (e) => {
        e.preventDefault();

        // Konfirmasi SweetAlert2
        const result = await MySwal.fire({
            title: 'Konfirmasi',
            text: 'Apakah Anda yakin ingin menyimpan perubahan ini?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya, Simpan',
            cancelButtonText: 'Batal',
            reverseButtons: true,
            customClass: {
                popup: 'custom-swal', // Menambahkan kelas kustom
            },
            background: 'transparent', // Agar background default tidak mengganggu
            iconColor: '#B24400', // Mengubah warna ikon
        });

        if (result.isConfirmed) {
            const formData = new FormData();
            formData.append('nama', nama);
            formData.append('alamat', alamat);
            formData.append('negara', negara);
            formData.append('tanggal_lahir', tanggalLahir);
            formData.append('agama', agama);
            formData.append('jenis_kelamin', jenisKelamin);
            formData.append('no_hp', noHp);
            formData.append('pendidikan', pendidikan);
            formData.append('tinggi_badan', tinggiBadan);
            formData.append('berat_badan', beratBadan);
            formData.append('status_pernikahan', statusPernikahan);
            formData.append('jumlah_saudara', jumlahSaudara);
            formData.append('jumlah_anak', jumlahAnak);
            formData.append('alergi', alergi);
            formData.append('tato', tato);
            formData.append('kemampuan_bahasa', kemampuanBahasa);
            formData.append('kemampuan_masak', kemampuanMasak);
            formData.append('nama_bandara', namaBandara);
            formData.append('pengalaman', pengalaman);
            formData.append('deskripsi', deskripsi);
            formData.append('sponsor', sponsor);
            formData.append('status', status);
            if (file) {
                formData.append('foto_profil', file);
            } else if (preview) {
                // Jika tidak ada file baru, kirim URL foto yang ada
                formData.append('foto_profil', preview);
            }
            try {
                await axios.put(`${baseApi}/helper/helpers/${id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                    },
                });

                // Tampilkan SweetAlert2 Toast ketika berhasil
                MySwal.fire({
                    icon: 'success',
                    title: 'Data berhasil diperbarui!',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    customClass: {
                        popup: 'custom-swal', // Menambahkan kelas kustom
                    },
                    background: 'transparent', // Agar background default tidak mengganggu
                    iconColor: '#409900', // Mengubah warna ikon
                });

                // Arahkan kembali ke halaman admin/helpers
                navigate('/admin/helpers');
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data);
                    setMessage(error.response.data.message);

                    // Tampilkan SweetAlert2 Toast untuk kesalahan
                    MySwal.fire({
                        icon: 'error',
                        title: 'Gagal memperbarui data!',
                        text: error.response.data.message,
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    });
                }
            }
        }
    };

    return (
        <div className="mt-[70px] p-[32px]">
            <div className="overscroll-contain">
                <h1 className="text-headline-md text-white">
                    Form Edit Helper
                </h1>

                <div className="my-[32px] flex">
                    <Link
                        to="/admin/helpers"
                        className="flex items-center gap-[6px] rounded-lg bg-[#4A4A4F] px-[18px] py-[12px] text-button-lg text-white hover:bg-[#515151]">
                        <IoMdArrowRoundBack size={18} className="fill-white" />
                        <caption>Kembali</caption>
                    </Link>
                </div>

                <div className="h-full w-full rounded-lg bg-[#202022] p-[32px]">
                    <form action="" onSubmit={updateHelpers}>
                        <div className="flex flex-row gap-12">
                            {/* FormKiri */}
                            <div className="flex w-[50%] flex-col gap-6">
                                <div className="">
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="nama">
                                        Nama
                                    </label>
                                    <input
                                        id="nama"
                                        type="text"
                                        name="nama"
                                        placeholder="Nama"
                                        value={nama}
                                        onChange={(e) =>
                                            setNama(e.target.value)
                                        }
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>
                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="tglLahir">
                                        Tanggal Lahir
                                    </label>
                                    <input
                                        id="tglLahir"
                                        type="date"
                                        name="tanggal_lahir"
                                        value={tanggalLahir}
                                        onChange={(e) =>
                                            setTanggalLahir(e.target.value)
                                        }
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>
                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="alamat">
                                        Alamat
                                    </label>
                                    <input
                                        id="alamat"
                                        type="text"
                                        name="alamat"
                                        placeholder="Alamat"
                                        value={alamat}
                                        onChange={(e) =>
                                            setAlamat(e.target.value)
                                        }
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>
                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="agama">
                                        Agama
                                    </label>
                                    <input
                                        id="agama"
                                        type="text"
                                        name="agama"
                                        placeholder="Agama"
                                        value={agama}
                                        onChange={(e) =>
                                            setAgama(e.target.value)
                                        }
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>
                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="statusPernikahan">
                                        Status Pernikahan
                                    </label>
                                    <select
                                        id="statusPernikahan"
                                        name="status_pernikahan"
                                        value={statusPernikahan}
                                        onChange={(e) =>
                                            setStatusPernikahan(e.target.value)
                                        }
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-[#202022] px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]">
                                        <option value="" disabled>
                                            Pilih Jenis Kelamin
                                        </option>
                                        <option value="Belum Kawin">
                                            Belum Kawin
                                        </option>
                                        <option value="Kawin">Kawin</option>
                                        <option value="Cerai Hidup">
                                            Cerai Hidup
                                        </option>
                                        <option value="Cerai mati">
                                            Cerai Mati
                                        </option>
                                    </select>
                                </div>
                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="negara">
                                        Kewarganegaraan
                                    </label>
                                    <input
                                        type="text"
                                        name="negara"
                                        placeholder="Negara"
                                        value={negara}
                                        onChange={(e) =>
                                            setNegara(e.target.value)
                                        }
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>
                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="noHp">
                                        No Hp
                                    </label>
                                    <input
                                        id="noHp"
                                        type="text"
                                        name="no_hp"
                                        placeholder="No HP"
                                        value={noHp}
                                        onChange={(e) =>
                                            setNoHp(e.target.value)
                                        }
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>
                            </div>
                            <div className="w-[1px] bg-slate-300"></div>
                            {/* FormKanan */}
                            <div className="flex w-[50%] flex-col gap-6">
                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="pendidikan">
                                        Pendidikan
                                    </label>
                                    <input
                                        id="pendidikan"
                                        type="text"
                                        name="pendidikan"
                                        placeholder="Pendidikan"
                                        value={pendidikan}
                                        onChange={(e) =>
                                            setPendidikan(e.target.value)
                                        }
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>
                                <div className="flex flex-row gap-4">
                                    <div>
                                        <label
                                            className="block text-subtitle-lg font-semibold text-white"
                                            id="tinggi">
                                            Tinggi Badan
                                        </label>
                                        <input
                                            id="tinggi"
                                            type="text"
                                            name="tinggi_badan"
                                            placeholder="Tinggi Badan (cm)"
                                            value={tinggiBadan}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (/^\d*$/.test(value)) {
                                                    // Hanya angka yang diizinkan
                                                    setPendidikan(
                                                        e.target.value,
                                                    );
                                                }
                                            }}
                                            className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                        />
                                    </div>
                                    <div>
                                        <label
                                            className="block text-subtitle-lg font-semibold text-white"
                                            id="berat">
                                            Berat Badan
                                        </label>
                                        <input
                                            id="berat"
                                            type="number"
                                            name="berat_badan"
                                            placeholder="Berat Badan (kg)"
                                            value={beratBadan}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (/^\d*$/.test(value)) {
                                                    // Hanya angka yang diizinkan
                                                    setBeratBadan(
                                                        e.target.value,
                                                    );
                                                }
                                            }}
                                            className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="saudara">
                                        Jumlah Saudara
                                    </label>
                                    <input
                                        id="saudara"
                                        type="number"
                                        name="jumlah_saudara"
                                        placeholder="Jumlah Saudara"
                                        value={jumlahSaudara}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*$/.test(value)) {
                                                // Hanya angka yang diizinkan
                                                setJumlahSaudara(
                                                    e.target.value,
                                                );
                                            }
                                        }}
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>
                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="anak">
                                        Jumlah Anak
                                    </label>
                                    <input
                                        id="anak"
                                        type="number"
                                        name="jumlah_anak"
                                        placeholder="Jumlah Anak"
                                        value={jumlahAnak}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*$/.test(value)) {
                                                // Hanya angka yang diizinkan
                                                setJumlahAnak(e.target.value);
                                            }
                                        }}
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>
                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="alergiPenyakit">
                                        Alergi
                                    </label>
                                    <input
                                        id="alergiPenyakit"
                                        type="text"
                                        name="alergi"
                                        placeholder="Alergi"
                                        value={alergi}
                                        onChange={(e) =>
                                            setAlergi(e.target.value)
                                        }
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>
                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="memilikiTato">
                                        Tato
                                    </label>
                                    <select
                                        id="memilikiTato"
                                        name="tato"
                                        value={tato}
                                        onChange={(e) =>
                                            setTato(e.target.value)
                                        }
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-[#202022] px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]">
                                        <option value="" disabled>
                                            Apakah memiliki tato?
                                        </option>
                                        <option value="Ya">Ya</option>
                                        <option value="Tidak">Tidak</option>
                                    </select>
                                </div>
                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="bandara">
                                        Nama Bandara
                                    </label>
                                    <input
                                        id="bandara"
                                        type="text"
                                        name="nama_bandara"
                                        placeholder="Nama Bandara Terdekat"
                                        value={namaBandara}
                                        onChange={(e) =>
                                            setNamaBandara(e.target.value)
                                        }
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>
                            </div>
                        </div>
                        {/* FormBawah */}
                        <div className="mt-4 flex flex-col gap-4">
                            <h2 className="text-xl font-bold text-white">
                                - Kemampuan dan Pengalaman
                            </h2>
                            <div>
                                <label
                                    className="block text-subtitle-lg font-semibold text-white"
                                    id="bahasa">
                                    Kemampuan Bahasa
                                </label>
                                <input
                                    id="bahasa"
                                    type="text"
                                    name="kemampuan_bahasa"
                                    placeholder="Kemampuan Bahasa"
                                    value={kemampuanBahasa}
                                    onChange={(e) =>
                                        setKemampuanBahasa(e.target.value)
                                    }
                                    className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                />
                            </div>

                            <div>
                                <label
                                    className="block text-subtitle-lg font-semibold text-white"
                                    id="masak">
                                    Kemampuan Memasak
                                </label>
                                <input
                                    id="masak"
                                    type="text"
                                    name="kemampuan_masak"
                                    placeholder="Kemampuan Masak"
                                    value={kemampuanMasak}
                                    onChange={(e) =>
                                        setKemampuanMasak(e.target.value)
                                    }
                                    className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                />
                            </div>

                            <div>
                                <label
                                    className="block text-subtitle-lg font-semibold text-white"
                                    id="pengalamanKerja">
                                    Pengalaman
                                </label>
                                <textarea
                                    id="pengalamanKerja"
                                    name="pengalaman"
                                    placeholder="Pengalaman"
                                    value={pengalaman}
                                    onChange={(e) =>
                                        setPengalaman(e.target.value)
                                    }
                                    className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                />
                            </div>

                            <div>
                                <label
                                    className="block text-subtitle-lg font-semibold text-white"
                                    id="pengalamanDetile">
                                    Deskripsi
                                </label>
                                <textarea
                                    id="pengalamanDetile"
                                    name="deskripsi"
                                    placeholder="Deskripsi Diri"
                                    value={deskripsi}
                                    onChange={(e) =>
                                        setDeskripsi(e.target.value)
                                    }
                                    className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                />
                            </div>

                            <div>
                                <label
                                    className="block text-subtitle-lg font-semibold text-white"
                                    id="sponsorHelper">
                                    Sponsor
                                </label>
                                <input
                                    id=""
                                    name="sponsorHelper"
                                    placeholder="sponsor"
                                    value={sponsor}
                                    onChange={(e) => setSponsor(e.target.value)}
                                    className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                />
                            </div>

                            <div>
                                <label
                                    className="block text-subtitle-lg font-semibold text-white"
                                    id="statusKerja">
                                    Status
                                </label>
                                <select
                                    id="statusKerja"
                                    name="status"
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                    className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-[#202022] px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]">
                                    <option value="" disabled>
                                        Pilih Status
                                    </option>
                                    <option value="available">Available</option>
                                    <option value="unpublished">
                                        Unpublished
                                    </option>
                                    <option value="sold out">Sold Out</option>
                                </select>
                            </div>

                            <div className="mt-4 block w-fit">
                                <span className="block text-subtitle-lg font-semibold text-white">
                                    Masukan Foto
                                </span>
                                <div className="mt-2">
                                    <span className="sr-only">
                                        Pilih foto profil
                                    </span>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={loadImage}
                                        className="text-sm file:text-sm block w-full text-[#f3f3f3] file:mr-4 file:rounded-lg file:border-0 file:bg-violet-50 file:px-4 file:py-2 file:font-semibold file:text-[#313131] hover:file:bg-violet-100"
                                    />
                                    <p className="text-red-600">{message}</p>
                                </div>
                                {preview && (
                                    <div class="mt-4 shrink-0">
                                        <img
                                            id="preview_img"
                                            className="h-[115px] w-[115px] rounded-lg object-cover"
                                            src={preview}
                                            alt={preview}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="mt-8 flex justify-end gap-2">
                            <Link
                                to="/admin/helpers"
                                className="flex items-center gap-[6px] rounded-lg bg-[#FF2E2E] px-4 py-2 text-button-lg text-white hover:bg-red-700">
                                <caption>Batal</caption>
                            </Link>

                            <button
                                type="submit"
                                className="rounded-lg bg-[#1AA053] px-4 py-2 text-button-lg text-white hover:bg-green-700">
                                Simpan
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditHelpers;
