import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import { baseApi } from '../utils/constant';
import axios from 'axios';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Navbar from '../components/Navbar';
import { IoLocationOutline } from 'react-icons/io5';
import { FaAngleRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

function ApplyJob() {
    const [nama, setNama] = useState('');
    const [pekerjaan, setPekerjaan] = useState('');
    const [kriteria, setKriteria] = useState('');
    const [alamat, setAlamat] = useState('');
    const [tipeRumah, setTipeRumah] = useState('');
    const [penghuni, setPenghuni] = useState('');
    const [gaji, setGaji] = useState('');
    const [potonganGaji, setPotonganGaji] = useState('');
    const [jumlahOff, setJumlahOff] = useState('');
    const [kualifikasiHelper, setKualifikasiHelper] = useState('');
    const [negara, setNegara] = useState('');
    const [rasMajikan, setRasMajikan] = useState('');
    const [message, setMessage] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [nameHelper, setNameHelper] = useState('');
    const [address, setAddress] = useState('');
    const navigate = useNavigate();
    const [today, setToday] = useState('');
    const form = useRef(); // Gunakan useRef untuk referensi form
    const { t } = useTranslation(); // Menggunakan useTranslation hook

    const { id } = useParams();
    const MySwal = withReactContent(Swal);

    useEffect(() => {
        const fetchData = async () => {
            await getEmployerById(id);
        };
        fetchData();
    }, [id]);

    useEffect(() => {
        // Get today's date in YYYY-MM-DD format
        const currentDate = new Date().toISOString().split('T')[0];
        setToday(currentDate);
    }, []);

    const getEmployerById = async (id) => {
        const response = await axios.get(`${baseApi}/employer/employers/${id}`);
        setNama(response.data.nama);
        setPekerjaan(response.data.pekerjaan);
        setKriteria(response.data.kriteria);
        setAlamat(response.data.alamat);
        setTipeRumah(response.data.tipe_rumah);
        setPenghuni(response.data.penghuni);
        setGaji(response.data.gaji);
        setPotonganGaji(response.data.potongan_gaji);
        setJumlahOff(response.data.jumlah_off);
        setKualifikasiHelper(response.data.kualifikasi_helper);
        setNegara(response.data.negara);
        setRasMajikan(response.data.ras_majikan);
    };

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                'service_5vitlwx',
                'template_jtueoxn',
                form.current,
                '3Rf5UsqAiuIIl5HDv',
            )
            .then(
                (result) => {
                    console.log('Email berhasil terkirim:', result.text);

                    // Tampilkan SweetAlert2 Toast ketika berhasil
                    MySwal.fire({
                        icon: 'success',
                        title: 'Email berhasil terkirim!',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        customClass: {
                            popup: 'custom-swal', // Menambahkan kelas kustom
                        },
                        background: 'transparent', // Agar background default tidak mengganggu
                        iconColor: '#409900', // Mengubah warna ikon
                    });

                    // Redirect setelah 3 detik (setelah toast hilang)
                    setTimeout(() => {
                        window.location.href = '/employerList'; // Ganti dengan link tujuan
                    }, 2000);
                },
                (error) => {
                    console.log('Gagal mengirim email:', error.text);

                    // Tampilkan SweetAlert2 Toast ketika gagal
                    MySwal.fire({
                        icon: 'error',
                        title: 'Gagal mengirim email!',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        customClass: {
                            popup: 'custom-swal',
                        },
                        background: 'transparent',
                        iconColor: '#ff0000', // Warna ikon untuk error
                    });
                },
            );

        // Clear form setelah pengiriman
        setNameHelper('');
        setAddress('');
        setPhoneNumber('');
    };

    // Fungsi untuk menangani input hanya angka
    const handlePhoneChange = (e) => {
        const newValue = e.target.value;

        // Hanya memperbolehkan angka
        if (/^\d*$/.test(newValue)) {
            setPhoneNumber(newValue);
        }
    };

    // Fungsi untuk menangani input umum
    const handleNameChange = (e) => {
        setNameHelper(e.target.value);
    };

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    };

    return (
        <div>
            <Navbar />
            <div className="min-h-screen w-full bg-[#19191A] px-[20px] py-20 lg:px-[112px] lg:pb-[32px]">
                <div className="mt-4 flex flex-wrap items-center gap-4 text-subtitle-lg font-medium text-gray-400">
                    <Link to="/" className="text-gray-400 hover:text-[#C9A254]">
                        Supplier Helper
                    </Link>
                    <FaAngleRight />
                    <Link
                        to="/employerList"
                        className="text-gray-400 hover:text-[#C9A254]">
                        Employer List
                    </Link>
                    <FaAngleRight />
                    <span className="text-[#C9A254]">Apply Job</span>
                </div>
                <div className="my-6 h-[1px] bg-[#414141]"></div>
                <h1 className="mb-4 text-headline-lg text-[#FFFFFF]">
                    {t('employers.applyJob.applyJob')}
                </h1>
                <div className="flex flex-col gap-4 lg:flex-row">
                    <div className="h-fit content-center rounded-lg bg-[#202022] px-[24px] py-[24px] text-body-lg shadow-cardShadow lg:w-[40%]">
                        <form ref={form} onSubmit={sendEmail}>
                            <label htmlFor="" className="mb-4 block">
                                <span className="font-semibold text-white">
                                    {t('employers.applyJob.name')}
                                </span>
                                <input
                                    className="placeholder mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    type="text"
                                    name="name" // Tambahkan name attribute agar sesuai dengan EmailJS
                                    value={nameHelper}
                                    onChange={handleNameChange}
                                    placeholder="Your name"
                                    id=""
                                    required
                                />
                            </label>
                            <label htmlFor="" className="mb-4 block">
                                <span className="font-semibold text-white">
                                    {t('employers.applyJob.emailAddress')}
                                </span>
                                <input
                                    className="placeholder mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    type="email"
                                    name="email" // Tambahkan name attribute agar sesuai dengan EmailJS
                                    value={address}
                                    onChange={handleAddressChange}
                                    placeholder="Your email address"
                                    id=""
                                    required
                                />
                            </label>
                            <label htmlFor="" className="block">
                                <span className="font-semibold text-white">
                                    {t('employers.applyJob.phoneNumber')}
                                </span>
                                <input
                                    className="placeholder mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    type="text"
                                    name="phone" // Tambahkan name attribute agar sesuai dengan EmailJS
                                    value={phoneNumber}
                                    onChange={handlePhoneChange}
                                    placeholder="Your phone number"
                                    id=""
                                    required
                                />
                            </label>

                            <input
                                type="hidden"
                                name="namaMajikan"
                                value={nama}
                                readOnly
                            />
                            <input
                                type="hidden"
                                name="pekerjaan"
                                value={pekerjaan}
                                readOnly
                            />
                            <input
                                type="hidden"
                                name="ras"
                                value={rasMajikan}
                                readOnly
                            />
                            <input
                                type="hidden"
                                name="negara"
                                value={negara}
                                readOnly
                            />
                            <input
                                type="hidden"
                                name="tipeRumah"
                                value={tipeRumah}
                                readOnly
                            />
                            <input
                                type="hidden"
                                name="penghuni"
                                value={penghuni}
                                readOnly
                            />
                            <input
                                type="hidden"
                                name="gaji"
                                value={gaji}
                                readOnly
                            />
                            <input
                                type="hidden"
                                name="potonganGaji"
                                value={potonganGaji}
                                readOnly
                            />
                            <input
                                type="hidden"
                                name="jumlahOff"
                                value={jumlahOff}
                                readOnly
                            />
                            <input
                                type="hidden"
                                name="kualifikasiHelper"
                                value={kualifikasiHelper}
                                readOnly
                            />
                            <input
                                type="hidden"
                                name="kualifikasiHelper"
                                value={kualifikasiHelper}
                                readOnly
                            />

                            <input
                                type="hidden"
                                name="bookingDate"
                                value={today}
                                readOnly
                            />

                            <button
                                className="mt-8 w-full rounded-md bg-[#F3B129] py-2 text-body-lg font-semibold text-white hover:bg-[#f6c359] hover:text-[#202022] focus:outline-none focus:ring-1 focus:ring-[#F3B129] focus:ring-offset-2"
                                type="submit">
                                {t('employers.applyJob.btnApply')}
                            </button>
                        </form>
                    </div>

                    <div className="h-auto rounded-lg bg-[#202022] text-white shadow-cardShadow lg:w-[60%]">
                        <div className="rounded-t-lg bg-[#414141] px-[24px] py-4 text-headline-md font-bold">
                            {t('employers.applyJob.detailsJob')}
                        </div>
                        <div className="px-[24px] py-[24px]">
                            <div className="w-auto gap-y-2 text-body-lg">
                                <p className="font-bold uppercase">{nama}</p>
                                <div className="flex items-center">
                                    <IoLocationOutline className="mr-2 fill-white" />
                                    <span className="whitespace-normal text-body-base font-light">
                                        {alamat}
                                    </span>
                                </div>
                                <p className="text-body-base font-light">
                                    Ras : {rasMajikan}
                                </p>
                            </div>

                            <div className="my-4 h-[1px] bg-[#414141]"></div>
                            <div className="flex justify-between">
                                <span className="text-body-base font-medium">
                                    {t('employers.applyJob.typeJob')}
                                </span>
                                <span>{pekerjaan}</span>
                            </div>
                            <div className="my-4 h-[1px] bg-[#414141]"></div>
                            <div className="flex justify-between">
                                <span className="text-body-base font-medium">
                                    {t('employers.applyJob.placement')}
                                </span>
                                <span>{negara}</span>
                            </div>
                            <div className="my-4 h-[1px] bg-[#414141]"></div>
                            <div className="flex justify-between">
                                <span className="text-body-base font-medium">
                                    {t('employers.applyJob.salary')}
                                </span>
                                <span>${gaji}</span>
                            </div>
                            <div className="my-4 h-[1px] bg-[#414141]"></div>
                            <div className="flex justify-between">
                                <span className="text-body-base font-medium">
                                    {t('employers.applyJob.salaryDeduction')}
                                </span>
                                <p className="max-w-xs break-words">
                                    ${potonganGaji}
                                </p>
                            </div>
                            <div className="my-4 h-[1px] bg-[#414141]"></div>
                            <div className="flex justify-between">
                                <span className="text-body-base font-medium">
                                    {t('employers.applyJob.daysOff')}
                                </span>
                                <p className="max-w-xs break-words">
                                    {jumlahOff}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ApplyJob;
