import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io';
import axios from 'axios';
import { baseApi } from '../../utils/constant';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

function AddBlog() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [expired, setExpired] = useState('');
    const [token, setToken] = useState('');

    const [file, setFile] = useState('');
    const [preview, setPreview] = useState('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const MySwal = withReactContent(Swal);

    const loadImage = (e) => {
        const image = e.target.files[0];
        setFile(image);
        setPreview(URL.createObjectURL(image));
    };

    useEffect(() => {
        // console.log('Refresh Token');
        refreshToken();
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseApi}/users/token`);
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            console.log(decoded);
            setUsername(decoded.username);
            setEmail(decoded.email);
            setExpired(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate('/login');
            }
        }
    };

    const axiosJwt = axios.create();

    axiosJwt.interceptors.request.use(
        async (config) => {
            const currentDate = new Date();
            if (expired < currentDate.getTime() / 1000) {
                try {
                    const response = await axios.get(`${baseApi}/users/token`);
                    const newToken = response.data.accessToken;
                    setToken(newToken);
                    config.headers.Authorization = `Bearer ${newToken}`;
                    const decoded = jwtDecode(newToken);
                    setUsername(decoded.username);
                    setEmail(decoded.email);
                    setExpired(decoded.exp);
                } catch (error) {
                    console.error('Error refreshing token:', error);
                    navigate('/login');
                }
            } else {
                config.headers.Authorization = `Bearer ${token}`;
            }

            // console.log('Authorization Header:', config.headers.Authorization);

            return config;
        },
        (error) => {
            return Promise.reject(error);
        },
    );

    const handleAddBlog = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('title', title);
        formData.append('content', content);
        formData.append('image', file);

        try {
            await axiosJwt.post(`${baseApi}/blog/addBlogs`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            // Tampilkan SweetAlert2 Toast ketika berhasil
            MySwal.fire({
                icon: 'success',
                title: 'Data berhasil ditambahkan!',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                customClass: {
                    popup: 'custom-swal', // Menambahkan kelas kustom
                },
                background: 'transparent', // Agar background default tidak mengganggu
                iconColor: '#409900', // Mengubah warna ikon
            });

            navigate('/admin/blogs');
        } catch (error) {
            if (error.response) {
                console.log(error.response.data);
                setMessage(error.response.data.message);
            }
        }
    };

    return (
        <div className="mt-[70px] p-[32px]">
            <h1 className="text-headline-md text-white">Add Blog/News</h1>
            <div className="my-[32px] flex">
                <Link
                    to="/admin/blogs"
                    className="flex items-center gap-[6px] rounded-lg bg-[#4A4A4F] px-[18px] py-[12px] text-button-lg text-white hover:bg-[#515151]">
                    <IoMdArrowRoundBack size={18} className="fill-white" />
                    <caption>Kembali</caption>
                </Link>
            </div>
            <div className="h-full w-full rounded-lg bg-[#202022] p-[32px]">
                <form onSubmit={handleAddBlog}>
                    <div className="flex flex-col gap-8">
                        <div>
                            <label
                                className="block text-subtitle-lg font-semibold text-white"
                                id="titleBlog">
                                Judul Blog
                            </label>
                            <input
                                id="titleBlog"
                                type="text"
                                name="title"
                                placeholder="Judul Blog"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                            />
                        </div>

                        <div>
                            <label
                                className="block text-subtitle-lg font-semibold text-white"
                                id="deskripsi">
                                Deskripsi
                            </label>
                            <textarea
                                id="deskripsi"
                                type="text"
                                name="content"
                                placeholder="Deskripsi Blog"
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                                className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                            />
                        </div>

                        <div className="mt-4 block border border-slate-300 p-4">
                            <span className="block text-subtitle-lg font-semibold text-white">
                                Masukan Foto
                            </span>
                            <span className="block text-subtitle-sm font-normal text-red-800">
                                Note : ukuran 200x300px*
                            </span>
                            <div className="mt-2 w-fit">
                                <span className="sr-only">
                                    Pilih foto profil
                                </span>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={loadImage}
                                    className="text-sm file:text-sm block w-full text-[#f3f3f3] file:mr-4 file:rounded-lg file:border-0 file:bg-violet-50 file:px-4 file:py-2 file:font-semibold file:text-[#313131] hover:file:bg-violet-100"
                                    required
                                />
                                <p className="text-red-600">{message}</p>
                            </div>
                            {preview && (
                                <div class="mt-4 shrink-0">
                                    <img
                                        id="preview_img"
                                        className="h-[200px] w-[300px] rounded-lg object-cover"
                                        src={preview}
                                        alt="Current profile photo"
                                    />
                                </div>
                            )}
                        </div>
                        <div className="mt-8 flex justify-end gap-2">
                            <Link
                                to="/admin/blogs"
                                className="flex items-center gap-[6px] rounded-lg bg-[#FF2E2E] px-4 py-2 text-button-lg text-white hover:bg-red-700">
                                <caption>Batal</caption>
                            </Link>
                            <button
                                type="submit"
                                className="rounded-lg bg-[#1AA053] px-4 py-2 text-button-lg text-white hover:bg-green-700">
                                Simpan
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddBlog;
