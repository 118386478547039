import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import logo from '../assets/svg/logo SH.svg';
import Bgabout from '../assets/img/BackgroundAbout.jpg';
import { useTranslation } from 'react-i18next';

function About() {
    const { t } = useTranslation(); // Menggunakan useTranslation hook

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <section
            style={{ backgroundImage: `url(${Bgabout})` }}
            className="w-full content-center items-center px-[20px] py-[46px] text-center text-[#19191A] lg:px-[112px] lg:py-[75px]"
            id="about">
            <div
                className="mb-8 text-center lg:mb-[0px]"
                data-aos="fade-up"
                data-aos-offset="0"
                data-aos-delay="10"
                data-aos-duration="1000"
                data-aos-anchor-placement="top-center">
                <h2 className="text-headline-lg"> {t('about.title')}!</h2>
            </div>
            <div
                className="lg:flex"
                data-aos="fade-up"
                data-aos-offset="-10"
                data-aos-delay="10"
                data-aos-duration="3000"
                data-aos-anchor-placement="top-center">
                <img
                    className="mx-auto mb-[24px] h-auto w-auto lg:mb-0"
                    src={logo}
                    alt=""
                    width="800"
                    height="600"
                />
                <p className="whitespace-normal text-center text-body-sm font-normal text-[#19191A] lg:ml-[50px] lg:py-[32px] lg:text-justify lg:text-body-lg">
                    {t('about.description')}
                </p>
            </div>
        </section>
    );
}

export default About;
