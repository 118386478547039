import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { baseApi } from '../utils/constant';
import axios from 'axios';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Navbar from '../components/Navbar';
import { useTranslation } from 'react-i18next';
import { FaAngleRight } from 'react-icons/fa';

function BookHelper() {
    const [nama, setNama] = useState('');
    const [alamat, setAlamat] = useState('');
    const [tanggalLahir, setTanggalLahir] = useState('');
    const [agama, setAgama] = useState('');
    const [jenisKelamin, setJenisKelamin] = useState('');
    const [tinggiBadan, setTinggiBadan] = useState('');
    const [beratBadan, setBeratBadan] = useState('');
    const [pengalaman, setPengalaman] = useState('');
    const [kemampuanBahasa, setKemampuanBahasa] = useState('');
    const [kemampuanMasak, setKemampuanMasak] = useState('');
    const [preview, setPreview] = useState('');
    const [value, setValue] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [nameEmployer, setNameEmployer] = useState('');
    const [address, setAddress] = useState('');
    const form = useRef(); // Gunakan useRef untuk referensi form
    const { t } = useTranslation(); // Menggunakan useTranslation hook

    const { id } = useParams();
    const MySwal = withReactContent(Swal);

    useEffect(() => {
        const fetchData = async () => {
            await getHelperById(id);
        };
        fetchData();
    }, [id]);

    const getHelperById = async (id) => {
        const response = await axios.get(`${baseApi}/helper/helpers/${id}`);
        setNama(response.data.nama);
        setAlamat(response.data.alamat);
        setTanggalLahir(response.data.tanggal_lahir);
        setAgama(response.data.agama);
        setJenisKelamin(response.data.jenis_kelamin);
        setTinggiBadan(response.data.tinggi_badan);
        setBeratBadan(response.data.berat_badan);
        setPengalaman(response.data.pengalaman);
        setKemampuanBahasa(response.data.kemampuan_bahasa);
        setKemampuanMasak(response.data.kemampuan_masak);
        setPreview(response.data.url);
    };

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                'service_5vitlwx',
                'template_b4cv8ar',
                form.current,
                '3Rf5UsqAiuIIl5HDv',
            )
            .then(
                (result) => {
                    console.log('Email berhasil terkirim:', result.text);

                    // Tampilkan SweetAlert2 Toast ketika berhasil
                    MySwal.fire({
                        icon: 'success',
                        title: 'Email berhasil terkirim!',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        customClass: {
                            popup: 'custom-swal', // Menambahkan kelas kustom
                        },
                        background: 'transparent', // Agar background default tidak mengganggu
                        iconColor: '#409900', // Mengubah warna ikon
                    });

                    // Redirect setelah 2 detik (setelah toast hilang)
                    setTimeout(() => {
                        window.location.href = '/helpersList'; // Ganti dengan link tujuan
                    }, 2000);
                },
                (error) => {
                    console.log('Gagal mengirim email:', error.text);

                    // Tampilkan SweetAlert2 Toast ketika gagal
                    MySwal.fire({
                        icon: 'error',
                        title: 'Gagal mengirim email!',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        customClass: {
                            popup: 'custom-swal',
                        },
                        background: 'transparent',
                        iconColor: '#ff0000', // Warna ikon untuk error
                    });
                },
            );

        // Clear form setelah pengiriman
        setNameEmployer('');
        setAddress('');
        setPhoneNumber('');
    };

    const formatTanggalLahir = (tanggal) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(tanggal).toLocaleDateString('id-ID', options);
    };

    const calculateAge = (birthDate) => {
        const today = new Date();
        const birthDateObj = new Date(birthDate);
        let age = today.getFullYear() - birthDateObj.getFullYear();
        const monthDifference = today.getMonth() - birthDateObj.getMonth();

        if (
            monthDifference < 0 ||
            (monthDifference === 0 && today.getDate() < birthDateObj.getDate())
        ) {
            age--;
        }

        return age;
    };

    // Fungsi untuk menangani input hanya angka
    const handlePhoneChange = (e) => {
        const newValue = e.target.value;

        // Hanya memperbolehkan angka
        if (/^\d*$/.test(newValue)) {
            setPhoneNumber(newValue);
        }
    };

    // Fungsi untuk menangani input umum
    const handleNameChange = (e) => {
        setNameEmployer(e.target.value);
    };

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    };

    return (
        <div className="min-h-screen bg-[#19191A]">
            <Navbar />
            <div className="min-h-screen w-full bg-[#19191A] px-[20px] py-20 lg:px-[112px] lg:pb-[32px]">
                <div className="mt-4 flex flex-wrap items-center gap-4 text-subtitle-lg font-medium text-gray-400">
                    <Link to="/" className="text-gray-400 hover:text-[#C9A254]">
                        Supplier Helper
                    </Link>
                    <FaAngleRight />
                    <Link
                        to="/helpersList"
                        className="text-gray-400 hover:text-[#C9A254]">
                        Helper List
                    </Link>
                    <FaAngleRight />
                    <span className="text-[#C9A254]">Book Helper</span>
                </div>

                <div className="my-6 h-[1px] bg-[#414141]"></div>
                <h1 className="mb-4 text-headline-lg text-[#FFFFFF]">
                    {t('helpers.bookNow.bookHelper')}
                </h1>

                <div className="flex flex-col gap-4 lg:flex-row">
                    <div className="h-fit content-center rounded-lg bg-[#202022] px-[24px] py-[24px] text-body-lg shadow-cardShadow lg:w-[40%]">
                        <form ref={form} onSubmit={sendEmail}>
                            <label htmlFor="" className="mb-4 block">
                                <span className="font-semibold text-white">
                                    {t('helpers.bookNow.name')}
                                </span>
                                <input
                                    className="placeholder mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    type="text"
                                    name="name" // Tambahkan name attribute agar sesuai dengan EmailJS
                                    value={nameEmployer}
                                    onChange={handleNameChange}
                                    placeholder="Your name"
                                    id=""
                                    required
                                />
                            </label>
                            <label htmlFor="" className="mb-4 block">
                                <span className="font-semibold text-white">
                                    {t('helpers.bookNow.emailAddress')}
                                </span>
                                <input
                                    className="placeholder mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    type="email"
                                    name="email" // Tambahkan name attribute agar sesuai dengan EmailJS
                                    value={address}
                                    onChange={handleAddressChange}
                                    placeholder="Your email address"
                                    id=""
                                    required
                                />
                            </label>
                            <label htmlFor="" className="block">
                                <span className="font-semibold text-white">
                                    {t('helpers.bookNow.phoneNumber')}
                                </span>
                                <input
                                    className="placeholder mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    type="text"
                                    name="phone" // Tambahkan name attribute agar sesuai dengan EmailJS
                                    value={phoneNumber}
                                    onChange={handlePhoneChange}
                                    placeholder="Your phone number"
                                    id=""
                                    required
                                />
                            </label>

                            <input
                                type="hidden"
                                name="nama_helper"
                                value={nama}
                                readOnly
                            />
                            <input
                                type="hidden"
                                name="tanggal_lahir"
                                value={tanggalLahir}
                                readOnly
                            />
                            <input
                                type="hidden"
                                name="kemampuan_bahasa"
                                value={kemampuanBahasa}
                                readOnly
                            />
                            <input
                                type="hidden"
                                name="kemampuan_masak"
                                value={kemampuanMasak}
                                readOnly
                            />
                            <input
                                type="hidden"
                                name="pengalaman"
                                value={pengalaman}
                                readOnly
                            />

                            <button
                                className="mt-8 w-full rounded-md bg-[#F3B129] py-2 text-body-lg font-semibold text-white hover:bg-[#f6c359] hover:text-[#202022] focus:outline-none focus:ring-1 focus:ring-[#F3B129] focus:ring-offset-2"
                                type="submit">
                                {t('helpers.bookNow.btnBookNow')}
                            </button>
                        </form>
                    </div>

                    <div className="h-auto rounded-lg bg-[#202022] text-white shadow-cardShadow lg:w-[60%]">
                        <div className="rounded-t-lg bg-[#414141] px-[24px] py-4 text-headline-md font-bold">
                            {t('helpers.bookNow.detailsHelper')}
                        </div>
                        <div className="px-[24px] py-[24px]">
                            <div className="flex items-center p-2">
                                <div className="">
                                    <img
                                        src={preview}
                                        alt=""
                                        className="aspect-[1/1] w-[100px] rounded-full object-cover"
                                    />
                                </div>

                                <div className="ml-[16px] w-auto text-body-lg">
                                    <h1 className="font-bold">{nama}</h1>
                                    <p className="font-light">{alamat}</p>
                                </div>
                            </div>
                            <div className="my-4 h-[1px] bg-[#414141]"></div>
                            <div className="grid grid-cols-2">
                                <span className="text-body-base font-medium">
                                    {t('helpers.bookNow.dateOfBirth')}
                                </span>
                                <span className="text-right">
                                    {formatTanggalLahir(tanggalLahir)} (
                                    {calculateAge(tanggalLahir)}){' '}
                                </span>
                            </div>
                            <div className="my-4 h-[1px] bg-[#414141]"></div>
                            <div className="grid grid-cols-2">
                                <span className="text-body-base font-medium">
                                    {t('helpers.card.languageSkills')}
                                </span>
                                <span className="text-right">
                                    {kemampuanBahasa}
                                </span>
                            </div>
                            <div className="my-4 h-[1px] bg-[#414141]"></div>
                            <div className="grid grid-cols-2">
                                <span className="text-body-base font-medium">
                                    {t('helpers.card.cookingSkills')}
                                </span>
                                <span className="text-right">
                                    {kemampuanMasak}
                                </span>
                            </div>
                            <div className="my-4 h-[1px] bg-[#414141]"></div>
                            <div className="grid grid-cols-2">
                                <span className="text-body-base font-medium">
                                    {t('helpers.card.experience')}
                                </span>
                                <p className="text-right">{pengalaman}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BookHelper;
