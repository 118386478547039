import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseApi } from '../utils/constant';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
// Icon
import { IoMenu, IoClose } from 'react-icons/io5';
import { IoArrowDownCircleOutline } from 'react-icons/io5';
import Profile from '../assets/howit1.jpg';

function NavbarAdmin({ toggleSidebar, isSidebarOpen }) {
    const navigate = useNavigate();

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    return (
        <div className="border-b border-[#4A4A4F] bg-[#111111]">
            <div className="z-10 flex h-[70px] w-full justify-between bg-[#19191A] pl-[32px] pr-[32px] shadow-cardShadow lg:py-[8px] lg:pl-0 lg:pr-[32px]">
                <div className="content-center justify-center align-middle lg:ml-[55px]">
                    <span className="font-bold uppercase text-[#FF971D]">
                        Supplier <span className="text-white">Helper</span>
                    </span>
                </div>
                <button
                    className="flex items-center lg:hidden"
                    onClick={toggleSidebar}>
                    {isSidebarOpen ? (
                        <IoClose size={24} className="fill-white text-white" />
                    ) : (
                        <IoMenu size={24} className="fill-white text-white" />
                    )}
                </button>
                <div className="hidden lg:block">
                    <button
                        onClick={() => navigate('/admin/profile')}
                        className="flex w-auto items-center rounded-md p-2">
                        <div className="flex">
                            <img
                                src={Profile}
                                alt=""
                                className="h-[40px] w-[40px] rounded-full object-cover"
                            />
                            <div className="ml-[16px] hidden text-left lg:block">
                                <p className="text-subtitle-sm font-bold text-[#FFFFFF]">
                                    {userInfo.username}
                                </p>
                                <p className="text-caption font-semibold text-[#FF971D]">
                                    {userInfo.role}
                                </p>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default NavbarAdmin;
