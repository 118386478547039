import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io';
import axios from 'axios';
import { baseApi } from '../../utils/constant';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import defaultImage from '../../assets/default_profile.png';

function AddHelpers() {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [expired, setExpired] = useState('');
    const [token, setToken] = useState('');
    const [helpers, setHelpers] = useState([]);

    const [file, setFile] = useState('');
    const [preview, setPreview] = useState('');
    const [nama, setNama] = useState('');
    const [alamat, setAlamat] = useState('');
    const [negara, setNegara] = useState('');
    const [tanggalLahir, setTanggalLahir] = useState('');
    const [agama, setAgama] = useState('');
    const [jenisKelamin, setJenisKelamin] = useState('Perempuan');
    const [noHp, setNoHp] = useState('');
    const [pendidikan, setPendidikan] = useState('');
    const [tinggiBadan, setTinggiBadan] = useState('');
    const [beratBadan, setBeratBadan] = useState('');
    const [statusPernikahan, setStatusPernikahan] = useState('');
    const [jumlahSaudara, setJumlahSaudara] = useState('');
    const [jumlahAnak, setJumlahAnak] = useState('');
    const [alergi, setAlergi] = useState('');
    const [tato, setTato] = useState('');
    const [kemampuanBahasa, setKemampuanBahasa] = useState('');
    const [kemampuanMasak, setKemampuanMasak] = useState('');
    const [namaBandara, setNamaBandara] = useState('');
    const [pengalaman, setPengalaman] = useState('');
    const [deskripsi, setDeskripsi] = useState('');
    const [sponsor, setSponsor] = useState('');
    const [status, setStatus] = useState('');
    const [message, setMessage] = useState('');

    const MySwal = withReactContent(Swal);

    // Fungsi untuk menangani perubahan input
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (files) {
            setFile(files[0]);
        } else {
            switch (name) {
                case 'nama':
                    setNama(value);
                    break;
                case 'alamat':
                    setAlamat(value);
                    break;
                case 'negara':
                    setNegara(value);
                    break;
                case 'tanggal_lahir':
                    setTanggalLahir(value);
                    break;
                case 'agama':
                    setAgama(value);
                    break;
                case 'jenis_kelamin':
                    setJenisKelamin(value);
                    break;
                case 'no_hp':
                    setNoHp(value);
                    break;
                case 'pendidikan':
                    setPendidikan(value);
                    break;
                case 'tinggi_badan':
                    setTinggiBadan(value);
                    break;
                case 'berat_badan':
                    setBeratBadan(value);
                    break;
                case 'status_pernikahan':
                    setStatusPernikahan(value);
                    break;
                case 'jumlah_saudara':
                    setJumlahSaudara(value);
                    break;
                case 'jumlah_anak':
                    setJumlahAnak(value);
                    break;
                case 'alergi':
                    setAlergi(value);
                    break;
                case 'tato':
                    setTato(value);
                    break;
                case 'nama_bandara':
                    setNamaBandara(value);
                    break;
                case 'kemampuan_bahasa':
                    setKemampuanBahasa(value);
                    break;
                case 'kemampuan_masak':
                    setKemampuanMasak(value);
                    break;
                case 'pengalaman':
                    setPengalaman(value);
                    break;
                case 'deskripsi':
                    setDeskripsi(value);
                    break;
                case 'sponsor':
                    setSponsor(value);
                    break;
                case 'status':
                    setStatus(value);
                    break;
                default:
                    break;
            }
        }
    };

    // Fungsi untuk mengubah langkah
    const nextStep = () => setStep(step + 1);
    const prevStep = () => setStep(step - 1);

    const loadImage = (e) => {
        const image = e.target.files[0];
        setFile(image);
        setPreview(URL.createObjectURL(image));
    };

    useEffect(() => {
        // console.log('Refresh Token');
        refreshToken();
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseApi}/users/token`);
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            console.log(decoded);
            setUsername(decoded.username);
            setEmail(decoded.email);
            setExpired(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate('/login');
            }
        }
    };

    const axiosJwt = axios.create();

    axiosJwt.interceptors.request.use(
        async (config) => {
            const currentDate = new Date();
            if (expired < currentDate.getTime() / 1000) {
                try {
                    const response = await axios.get(`${baseApi}/users/token`);
                    const newToken = response.data.accessToken;
                    setToken(newToken);
                    config.headers.Authorization = `Bearer ${newToken}`;
                    const decoded = jwtDecode(newToken);
                    setUsername(decoded.username);
                    setEmail(decoded.email);
                    setExpired(decoded.exp);
                } catch (error) {
                    console.error('Error refreshing token:', error);
                    navigate('/login');
                }
            } else {
                config.headers.Authorization = `Bearer ${token}`;
            }

            // console.log('Authorization Header:', config.headers.Authorization);

            return config;
        },
        (error) => {
            return Promise.reject(error);
        },
    );

    const handleAddHelpers = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('nama', nama);
        formData.append('alamat', alamat);
        formData.append('negara', negara);
        formData.append('tanggal_lahir', tanggalLahir);
        formData.append('agama', agama);
        formData.append('jenis_kelamin', jenisKelamin);
        formData.append('no_hp', noHp);
        formData.append('pendidikan', pendidikan);
        formData.append('tinggi_badan', tinggiBadan);
        formData.append('berat_badan', beratBadan);
        formData.append('status_pernikahan', statusPernikahan);
        formData.append('jumlah_saudara', jumlahSaudara);
        formData.append('jumlah_anak', jumlahAnak);
        formData.append('alergi', alergi);
        formData.append('tato', tato);
        formData.append('kemampuan_bahasa', kemampuanBahasa);
        formData.append('kemampuan_masak', kemampuanMasak);
        formData.append('nama_bandara', namaBandara);
        formData.append('pengalaman', pengalaman);
        formData.append('deskripsi', deskripsi);
        formData.append('sponsor', sponsor);
        formData.append('status', status);
        formData.append('foto_profil', file);

        try {
            await axiosJwt.post(`${baseApi}/helper/addHelpers`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            // Tampilkan SweetAlert2 Toast ketika berhasil
            MySwal.fire({
                icon: 'success',
                title: 'Data berhasil ditambahkan!',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                customClass: {
                    popup: 'custom-swal', // Menambahkan kelas kustom
                },
                background: 'transparent', // Agar background default tidak mengganggu
                iconColor: '#409900', // Mengubah warna ikon
            });

            navigate('/admin/helpers');
        } catch (error) {
            if (error.response) {
                console.log(error.response.data);
                setMessage(error.response.data.message);
            }
        }
    };

    return (
        <div className="mt-[70px] p-[32px]">
            <div className="overscroll-contain">
                <h1 className="text-headline-md text-white">Form Add Helper</h1>
                <div className="my-[32px] flex">
                    <Link
                        to="/admin/helpers"
                        className="flex items-center gap-[6px] rounded-lg bg-[#4A4A4F] px-[18px] py-[12px] text-button-lg text-white hover:bg-[#515151]">
                        <IoMdArrowRoundBack size={18} className="fill-white" />
                        <caption>Kembali</caption>
                    </Link>
                </div>

                <div className="h-full w-full rounded-lg bg-[#202022] p-[32px]">
                    <form onSubmit={handleAddHelpers}>
                        {/* Step 1 */}
                        {step === 1 && (
                            <div className="flex flex-col gap-4">
                                <h2 className="text-xl mb-4 font-bold">
                                    Step 1: Data Pribadi
                                </h2>
                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="nama">
                                        Nama
                                    </label>
                                    <input
                                        id="nama"
                                        type="text"
                                        name="nama"
                                        placeholder="Nama"
                                        value={nama}
                                        onChange={handleChange}
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>

                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="tglLahir">
                                        Tanggal Lahir
                                    </label>
                                    <input
                                        id="tglLahir"
                                        type="date"
                                        name="tanggal_lahir"
                                        value={tanggalLahir}
                                        onChange={handleChange}
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>

                                <div className="hidden">
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="tglLahir">
                                        Jenis Kelamin
                                    </label>
                                    <select
                                        name="jenis_kelamin"
                                        value={jenisKelamin}
                                        onChange={handleChange}
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-[#202022] px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]">
                                        <option value="" disabled>
                                            Pilih Jenis Kelamin
                                        </option>
                                        <option value="Laki-laki">
                                            Laki-laki
                                        </option>
                                        <option value="Perempuan">
                                            Perempuan
                                        </option>
                                    </select>
                                </div>

                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="alamat">
                                        Alamat
                                    </label>
                                    <input
                                        id="alamat"
                                        type="text"
                                        name="alamat"
                                        placeholder="Alamat"
                                        value={alamat}
                                        onChange={handleChange}
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>

                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="agama">
                                        Agama
                                    </label>
                                    <input
                                        id="agama"
                                        type="text"
                                        name="agama"
                                        placeholder="Agama"
                                        value={agama}
                                        onChange={handleChange}
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>

                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="statusPernikahan">
                                        Status Pernikahan
                                    </label>
                                    <select
                                        id="statusPernikahan"
                                        name="status_pernikahan"
                                        value={statusPernikahan}
                                        onChange={handleChange}
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-[#202022] px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]">
                                        <option value="" disabled>
                                            Pilih Status Perkawinan
                                        </option>
                                        <option value="Belum Kawin">
                                            Belum Kawin
                                        </option>
                                        <option value="Kawin">Kawin</option>
                                        <option value="Cerai Hidup">
                                            Cerai Hidup
                                        </option>
                                        <option value="Cerai mati">
                                            Cerai Mati
                                        </option>
                                    </select>
                                </div>

                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="negara">
                                        Kewarganegaraan
                                    </label>
                                    <input
                                        type="text"
                                        name="negara"
                                        placeholder="Negara"
                                        value={negara}
                                        onChange={handleChange}
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>

                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="noHp">
                                        No Hp
                                    </label>
                                    <input
                                        id="noHp"
                                        type="text"
                                        name="no_hp"
                                        placeholder="No HP"
                                        value={noHp}
                                        onChange={handleChange}
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>
                            </div>
                        )}

                        {/* Step 2 */}
                        {step === 2 && (
                            <div className="flex flex-col gap-4">
                                <h2 className="text-xl mb-4 font-bold">
                                    Step 2: Informasi Tambahan
                                </h2>

                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="pendidikan">
                                        Pendidikan
                                    </label>
                                    <input
                                        id="pendidikan"
                                        type="text"
                                        name="pendidikan"
                                        placeholder="Pendidikan"
                                        value={pendidikan}
                                        onChange={handleChange}
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>

                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="tinggi">
                                        Tinggi Badan
                                    </label>
                                    <input
                                        id="tinggi"
                                        type="text"
                                        name="tinggi_badan"
                                        placeholder="Tinggi Badan (cm)"
                                        value={tinggiBadan}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*$/.test(value)) {
                                                // Hanya angka yang diizinkan
                                                handleChange(e);
                                            }
                                        }}
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>

                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="berat">
                                        Berat Badan
                                    </label>
                                    <input
                                        id="berat"
                                        type="number"
                                        name="berat_badan"
                                        placeholder="Berat Badan (kg)"
                                        value={beratBadan}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*$/.test(value)) {
                                                // Hanya angka yang diizinkan
                                                handleChange(e);
                                            }
                                        }}
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>

                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="saudara">
                                        Jumlah Saudara
                                    </label>
                                    <input
                                        id="saudara"
                                        type="number"
                                        name="jumlah_saudara"
                                        placeholder="Jumlah Saudara"
                                        value={jumlahSaudara}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*$/.test(value)) {
                                                // Hanya angka yang diizinkan
                                                handleChange(e);
                                            }
                                        }}
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>

                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="anak">
                                        Jumlah Anak
                                    </label>
                                    <input
                                        id="anak"
                                        type="number"
                                        name="jumlah_anak"
                                        placeholder="Jumlah Anak"
                                        value={jumlahAnak}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*$/.test(value)) {
                                                // Hanya angka yang diizinkan
                                                handleChange(e);
                                            }
                                        }}
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>

                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="alergiPenyakit">
                                        Alergi
                                    </label>
                                    <input
                                        id="alergiPenyakit"
                                        type="text"
                                        name="alergi"
                                        placeholder="Alergi"
                                        value={alergi}
                                        onChange={handleChange}
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>

                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="memilikiTato">
                                        Tato
                                    </label>
                                    <select
                                        id="memilikiTato"
                                        name="tato"
                                        value={tato}
                                        onChange={handleChange}
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-[#202022] px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]">
                                        <option value="" disabled>
                                            Apakah memiliki tato?
                                        </option>
                                        <option value="Ya">Ya</option>
                                        <option value="Tidak">Tidak</option>
                                    </select>
                                </div>

                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="bandara">
                                        Nama Bandara
                                    </label>
                                    <input
                                        id="bandara"
                                        type="text"
                                        name="nama_bandara"
                                        placeholder="Nama Bandara Terdekat"
                                        value={namaBandara}
                                        onChange={handleChange}
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>
                            </div>
                        )}

                        {/* Step 3 */}
                        {step === 3 && (
                            <div className="flex flex-col gap-4">
                                <h2 className="text-xl mb-4 font-bold">
                                    Step 3: Kemampuan dan Pengalaman
                                </h2>
                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="bahasa">
                                        Kemampuan Bahasa
                                    </label>
                                    <input
                                        id="bahasa"
                                        type="text"
                                        name="kemampuan_bahasa"
                                        placeholder="Kemampuan Bahasa"
                                        value={kemampuanBahasa}
                                        onChange={handleChange}
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>

                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="masak">
                                        Kemampuan Memasak
                                    </label>
                                    <input
                                        id="masak"
                                        type="text"
                                        name="kemampuan_masak"
                                        placeholder="Kemampuan Masak"
                                        value={kemampuanMasak}
                                        onChange={handleChange}
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>

                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="pengalamanKerja">
                                        Pengalaman
                                    </label>
                                    <input
                                        id="pengalamanKerja"
                                        name="pengalaman"
                                        placeholder="Pengalaman"
                                        value={pengalaman}
                                        onChange={handleChange}
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>

                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="pengalamanDetile">
                                        Deskripsi
                                    </label>
                                    <textarea
                                        id="pengalamanDetile"
                                        name="deskripsi"
                                        placeholder="Deskripsi Diri"
                                        value={deskripsi}
                                        onChange={handleChange}
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>

                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="sponsorHelper">
                                        Sponsor
                                    </label>
                                    <input
                                        id="sponsorHelper"
                                        name="sponsor"
                                        placeholder="sponsor"
                                        value={sponsor}
                                        onChange={handleChange}
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-transparent px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]"
                                    />
                                </div>

                                <div>
                                    <label
                                        className="block text-subtitle-lg font-semibold text-white"
                                        id="statusKerja">
                                        Status
                                    </label>
                                    <select
                                        id="statusKerja"
                                        name="status"
                                        value={status}
                                        onChange={handleChange}
                                        className="placeholder- mt-2 block w-full rounded-md border border-slate-300 bg-[#202022] px-3 py-2 text-[#f3f3f3] placeholder-[#414141] shadow-sm focus:border-[#F3B129] focus:outline-none focus:ring-1 focus:ring-[#F3B129]">
                                        <option value="" disabled>
                                            Pilih Status
                                        </option>
                                        <option value="available">
                                            Available
                                        </option>
                                        <option value="unpublished">
                                            Unpublished
                                        </option>
                                        <option value="sold out">
                                            Sold Out
                                        </option>
                                    </select>
                                </div>

                                <div className="mt-4 block w-fit">
                                    <span className="block text-subtitle-lg font-semibold text-white">
                                        Masukan Foto
                                    </span>
                                    <span className="text-body-sm text-red-600">
                                        maximun upload foto 1MB, Ukuran foto 1:1
                                    </span>
                                    <div className="mt-2">
                                        <span className="sr-only">
                                            Pilih foto profil
                                        </span>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={loadImage}
                                            className="text-sm file:text-sm block w-full text-[#f3f3f3] file:mr-4 file:rounded-lg file:border-0 file:bg-violet-50 file:px-4 file:py-2 file:font-semibold file:text-[#313131] hover:file:bg-violet-100"
                                            required
                                        />
                                        <p className="text-red-600">
                                            {message}
                                        </p>
                                    </div>
                                    {preview && (
                                        <div class="mt-4 shrink-0">
                                            <img
                                                id="preview_img"
                                                className="h-[115px] w-[115px] rounded-lg object-cover"
                                                src={preview}
                                                alt="Current profile photo"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        {/* Kontrol Navigasi Form */}
                        <div className="mt-4 flex justify-end gap-3">
                            {step > 1 && (
                                <button
                                    type="button"
                                    onClick={prevStep}
                                    className="rounded bg-gray-500 px-4 py-2 text-white">
                                    Sebelumnya
                                </button>
                            )}
                            {step < 3 ? (
                                <button
                                    type="button"
                                    onClick={nextStep}
                                    className="rounded bg-blue-500 px-4 py-2 text-white">
                                    Berikutnya
                                </button>
                            ) : (
                                <button
                                    type="submit"
                                    className="rounded bg-green-500 px-4 py-2 text-white">
                                    Simpan
                                </button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AddHelpers;
