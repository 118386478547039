import React, { useEffect, useState, useRef } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { FaFacebook } from 'react-icons/fa';
import { BiSolidPhoneCall } from 'react-icons/bi';
import { MdEmail } from 'react-icons/md';
import { RiInstagramFill } from 'react-icons/ri';
import { LuSendHorizonal } from 'react-icons/lu';
import ElementCard from '../assets/svg/elementCard.svg';

function ContactUs() {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [message, setMessage] = useState('');
    const form = useRef(); // Gunakan useRef untuk referensi form

    const MySwal = withReactContent(Swal);

    useEffect(() => {
        AOS.init();
    }, []);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                'service_mj19wh1',
                'template_mfq8vm4',
                form.current,
                'jWtt9LZEoMs1jAa3Z',
            )
            .then(
                (result) => {
                    console.log('Email berhasil terkirim:', result.text);

                    // Tampilkan SweetAlert2 Toast ketika berhasil
                    MySwal.fire({
                        icon: 'success',
                        title: 'Email berhasil terkirim!',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        customClass: {
                            popup: 'custom-swal', // Menambahkan kelas kustom
                        },
                        background: 'transparent', // Agar background default tidak mengganggu
                        iconColor: '#409900', // Mengubah warna ikon
                    });

                    // Redirect setelah 2 detik (setelah toast hilang)
                    setTimeout(() => {
                        window.location.href = '/'; // Ganti dengan link tujuan
                    }, 2000);
                },
                (error) => {
                    console.log('Gagal mengirim email:', error.text);

                    // Tampilkan SweetAlert2 Toast ketika gagal
                    MySwal.fire({
                        icon: 'error',
                        title: 'Gagal mengirim email!',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        customClass: {
                            popup: 'custom-swal',
                        },
                        background: 'transparent',
                        iconColor: '#ff0000', // Warna ikon untuk error
                    });
                },
            );

        // Clear form setelah pengiriman
        setFirstName('');
        setLastName('');
        setAddress('');
        setMessage('');
        setPhoneNumber('');
    };

    // Fungsi untuk menangani input hanya angka
    const handlePhoneChange = (e) => {
        const newValue = e.target.value;

        // Hanya memperbolehkan angka
        if (/^\d*$/.test(newValue)) {
            setPhoneNumber(newValue);
        }
    };

    // Fungsi untuk menangani input umum
    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    // Fungsi untuk menangani input umum
    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    };

    // Fungsi untuk menangani input umum
    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    return (
        <section
            className="relative w-full bg-[#19191A] px-[20px] py-[46px] lg:px-[112px]"
            id="contact">
            <div
                className="m-auto justify-between lg:flex"
                data-aos="fade-up"
                data-aos-offset="150"
                data-aos-delay="10"
                data-aos-duration="1000">
                <div className="relative h-[400px] w-auto overflow-hidden rounded-lg bg-gold-gradient lg:mb-0 lg:h-[510px] lg:w-[491px]">
                    <img
                        src={ElementCard}
                        alt=""
                        className="absolute left-[200px] top-[270px] h-[200px] w-[200px] lg:left-[300px] lg:top-[340px] lg:h-auto lg:w-auto"
                    />
                    <div className="px-[24px]">
                        <div className="top-0 mb-[50px] mt-[40px] text-[#F5F5F5]">
                            <h2 className="text-headline-sm lg:text-headline-lg">
                                Contact Information
                            </h2>
                            <p className="text-body-sm lg:text-body-lg">
                                Say something to start a live chat!
                            </p>
                        </div>

                        <div className="top-0 m-auto mb-[16px] flex items-start text-[#F5F5F5]">
                            <div className="mr-[24px]">
                                <BiSolidPhoneCall size={25} />
                            </div>
                            <p className="text-subtitle-lg">
                                +62 8227 7812 400 <br />
                                +65 9241 6495
                            </p>
                        </div>

                        <div className="top-0 m-auto mb-[16px] flex items-center text-[#F5F5F5]">
                            <div className="mr-[24px]">
                                <MdEmail size={25} />
                            </div>
                            <p className="text-subtitle-lg">
                                s.helperindonesia@gmail.com
                            </p>
                        </div>

                        <div className="top-0 m-auto mb-[16px] flex items-center text-[#F5F5F5]">
                            <div className="mr-[24px]">
                                <FaFacebook size={25} />
                            </div>
                            <p className="text-subtitle-lg">Supplier Helper</p>
                        </div>

                        <div className="top-0 m-auto flex items-center text-[#F5F5F5]">
                            <div className="mr-[24px]">
                                <RiInstagramFill size={25} />
                            </div>
                            <p className="text-subtitle-lg">
                                s.helperindonesia
                            </p>
                        </div>
                    </div>
                </div>

                <div className="w-auto py-[24px] lg:ml-[24px]">
                    <form ref={form} onSubmit={sendEmail}>
                        <div className="lg:flex">
                            <div className="w-full items-center justify-center lg:relative lg:mr-[24px] lg:w-[280px]">
                                <p className="mb-[12px] text-subtitle-lg text-[#F5F5F5]">
                                    First Name
                                </p>
                                <input
                                    type="text"
                                    name="first_name"
                                    value={firstName}
                                    onChange={handleFirstNameChange}
                                    placeholder="First Name"
                                    className="mb-[12px] w-full bg-transparent py-[12px] text-body-sm text-[#F5F5F5] opacity-50 hover:opacity-100 lg:w-[280px]"
                                />
                                <div className="h-[2px] bg-[#F5F5F5]"></div>
                            </div>

                            <div className="mt-[42px] w-full items-center justify-center lg:relative lg:mt-0 lg:w-[280px]">
                                <p className="mb-[12px] text-subtitle-lg text-[#F5F5F5]">
                                    Last Name
                                </p>
                                <input
                                    type="text"
                                    name="last_name"
                                    value={lastName}
                                    onChange={handleLastNameChange}
                                    placeholder="Last Name"
                                    className="mb-[12px] w-full bg-transparent py-[12px] text-body-sm text-[#F5F5F5] opacity-50 hover:opacity-100 lg:w-[280px]"
                                />
                                <div className="h-[2px] bg-[#F5F5F5]"></div>
                            </div>
                        </div>

                        <div className="mt-[45px] lg:flex">
                            <div className="relative w-full items-center justify-center lg:mr-[24px] lg:w-[280px]">
                                <p className="mb-[12px] text-subtitle-lg text-[#F5F5F5]">
                                    Email
                                </p>
                                <input
                                    type="email"
                                    name="email"
                                    value={address}
                                    onChange={handleAddressChange}
                                    placeholder="Email"
                                    className="mb-[12px] w-full bg-transparent py-[12px] text-body-sm text-[#F5F5F5] opacity-50 hover:opacity-100 lg:w-[280px]"
                                />
                                <div className="h-[2px] bg-[#F5F5F5]"></div>
                            </div>

                            <div className="relative mt-[42px] w-full items-center justify-center lg:mt-0 lg:w-[280px]">
                                <p className="mb-[12px] text-subtitle-lg text-[#F5F5F5]">
                                    Phone Number
                                </p>
                                <input
                                    type="text"
                                    name="phone"
                                    value={phoneNumber}
                                    onChange={handlePhoneChange}
                                    placeholder="Phone Number"
                                    className="mb-[12px] w-full bg-transparent py-[12px] text-body-sm text-[#F5F5F5] opacity-50 hover:opacity-100 lg:w-[280px]"
                                />
                                <div className="h-[2px] bg-[#F5F5F5]"></div>
                            </div>
                        </div>

                        <div className="mt-[45px] flex">
                            <div className="relative w-full items-center justify-center">
                                <p className="mb-[12px] text-subtitle-lg text-[#F5F5F5]">
                                    Message
                                </p>
                                <input
                                    type="text"
                                    name="messageGuest"
                                    value={message}
                                    onChange={handleMessageChange}
                                    placeholder="Write your message.."
                                    className="mb-[12px] w-full bg-transparent py-[12px] text-body-sm text-[#F5F5F5] opacity-50 hover:opacity-100"
                                />
                                <div className="h-[2px] bg-[#F5F5F5]"></div>
                            </div>
                        </div>

                        <div className="mt-[45px] flex lg:justify-end">
                            <button className="btn btn-active" type="submit">
                                Send Message <LuSendHorizonal />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default ContactUs;
