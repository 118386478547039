import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AOS from 'aos';
import 'aos/dist/aos.css';
import HowitBg from '../assets/howit.jpg';
import Howit1 from '../assets/howit1.jpg';
import Howit2 from '../assets/howit2.jpg';
import Howit3 from '../assets/howit3.jpg';

function Howit() {
    const { t } = useTranslation(); // Menggunakan useTranslation hook

    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <section
            className="relative w-full bg-[#19191A] px-[20px] py-[46px] text-white lg:px-[112px] lg:py-[75px]"
            id="howit">
            <div className="z-10 mb-8 text-center lg:mb-20">
                <h2
                    className="mb-[8px] text-headline-lg lg:mb-[16px]"
                    data-aos="fade-up"
                    data-aos-offset="10"
                    data-aos-delay="10"
                    data-aos-duration="1000">
                    {t('howitwork.title')}!
                </h2>
                <p
                    className="text-headline-sm font-light"
                    data-aos="fade-up"
                    data-aos-offset="10"
                    data-aos-delay="10"
                    data-aos-duration="1000">
                    {t('howitwork.description')}!
                </p>
            </div>
            <div className="flex flex-col justify-between gap-4">
                <div
                    data-aos="fade-up"
                    data-aos-offset="100"
                    data-aos-delay="10"
                    data-aos-duration="3000"
                    className="flex flex-auto flex-col py-2 lg:flex-row lg:py-4">
                    <div className="flex flex-col gap-4 lg:mr-[42px] lg:w-[50%]">
                        <div className="group gap-4 rounded-lg bg-[#202022] p-4 shadow-lg hover:bg-[#C9A254] lg:items-center">
                            <h2 className="mb-2 text-headline-sm font-medium md:font-medium lg:text-headline-md">
                                {t('howitwork.initialInquiry.title')}
                            </h2>
                            <div className="relative flex">
                                <div className="mr-2 w-2 rounded-full bg-[#C9A254] group-hover:bg-white"></div>
                                <p className="text-subtitle-sm md:font-light lg:text-subtitle-lg lg:font-light">
                                    {t('howitwork.initialInquiry.description')}
                                </p>
                            </div>
                        </div>

                        <div className="group gap-4 rounded-lg bg-[#202022] p-4 shadow-lg hover:bg-[#C9A254] lg:items-center">
                            <h2 className="mb-2 text-headline-sm font-medium md:font-medium lg:text-headline-md">
                                {t('howitwork.consultation.title')}
                            </h2>
                            <div className="relative flex">
                                <div className="mr-2 w-2 rounded-full bg-[#C9A254] group-hover:bg-white"></div>
                                <p className="text-subtitle-sm md:font-light lg:text-subtitle-lg lg:font-light">
                                    {t('howitwork.consultation.description')}
                                </p>
                            </div>
                        </div>

                        <div className="group gap-4 rounded-lg bg-[#202022] p-4 shadow-lg hover:bg-[#C9A254] lg:items-center">
                            <h2 className="mb-2 text-headline-sm font-medium md:font-medium lg:text-headline-md">
                                {t('howitwork.profileMatching.title')}
                            </h2>
                            <div className="relative flex">
                                <div className="mr-2 w-2 rounded-full bg-[#C9A254] group-hover:bg-white"></div>
                                <p className="text-subtitle-sm md:font-light lg:text-subtitle-lg lg:font-light">
                                    {t('howitwork.profileMatching.description')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 lg:mt-0 lg:w-[50%]">
                        <img
                            className="w-full rounded-lg object-cover lg:h-full"
                            src={Howit1}
                            alt=""
                            width="800"
                            height="600"
                        />
                    </div>
                </div>

                <div
                    data-aos="fade-up"
                    data-aos-offset="100"
                    data-aos-delay="10"
                    data-aos-duration="3000"
                    className="flex flex-auto flex-col py-2 lg:flex-row-reverse lg:py-4">
                    <div className="flex flex-col gap-4 lg:ml-[42px] lg:w-[50%]">
                        <div className="group gap-4 rounded-lg bg-[#202022] p-4 shadow-lg hover:bg-[#C9A254] lg:items-center">
                            <h2 className="mb-2 text-headline-sm font-medium md:font-medium lg:text-headline-md">
                                {t('howitwork.interviewArrangement.title')}
                            </h2>
                            <div className="relative flex">
                                <div className="mr-2 w-2 rounded-full bg-[#C9A254] group-hover:bg-white"></div>
                                <p className="text-subtitle-sm md:font-light lg:text-subtitle-lg lg:font-light">
                                    {t(
                                        'howitwork.interviewArrangement.description',
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="group gap-4 rounded-lg bg-[#202022] p-4 shadow-lg hover:bg-[#C9A254] lg:items-center">
                            <h2 className="mb-2 text-headline-sm font-medium md:font-medium lg:text-headline-md">
                                {t('howitwork.selectionAndAgreement.title')}
                            </h2>
                            <div className="relative flex">
                                <div className="mr-2 w-2 rounded-full bg-[#C9A254] group-hover:bg-white"></div>
                                <p className="text-subtitle-sm md:font-light lg:text-subtitle-lg lg:font-light">
                                    {t(
                                        'howitwork.selectionAndAgreement.description',
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="group gap-4 rounded-lg bg-[#202022] p-4 shadow-lg hover:bg-[#C9A254] lg:items-center">
                            <h2 className="mb-2 text-headline-sm font-medium md:font-medium lg:text-headline-md">
                                {t(
                                    'howitwork.documentationAndLegalCompliance.title',
                                )}
                            </h2>
                            <div className="relative flex">
                                <div className="mr-2 w-2 rounded-full bg-[#C9A254] group-hover:bg-white"></div>
                                <p className="text-subtitle-sm md:font-light lg:text-subtitle-lg lg:font-light">
                                    {t(
                                        'howitwork.documentationAndLegalCompliance.description',
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="mt-4 lg:mt-0 lg:w-[50%]">
                        <img
                            className="w-full rounded-lg object-cover lg:h-full"
                            src={Howit2}
                            alt=""
                            width="800"
                            height="600"
                        />
                    </div>
                </div>

                <div
                    data-aos="fade-up"
                    data-aos-offset="100"
                    data-aos-delay="10"
                    data-aos-duration="3000"
                    className="flex flex-auto flex-col py-2 lg:flex-row lg:py-4">
                    <div className="flex flex-col gap-4 lg:mr-[42px] lg:w-[50%]">
                        <div className="group gap-4 rounded-lg bg-[#202022] p-4 shadow-lg hover:bg-[#C9A254] lg:items-center">
                            <h2 className="mb-2 text-headline-sm font-medium md:font-medium lg:text-headline-md">
                                {t('howitwork.trainingAndOrientation.title')}
                            </h2>
                            <div className="relative flex">
                                <div className="mr-2 w-2 rounded-full bg-[#C9A254] group-hover:bg-white"></div>
                                <p className="text-subtitle-sm md:font-light lg:text-subtitle-lg lg:font-light">
                                    {t(
                                        'howitwork.trainingAndOrientation.description',
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="group gap-4 rounded-lg bg-[#202022] p-4 shadow-lg hover:bg-[#C9A254] lg:items-center">
                            <h2 className="mb-2 text-headline-sm font-medium md:font-medium lg:text-headline-md">
                                {t('howitwork.placementAndIntegration.title')}
                            </h2>
                            <div className="relative flex">
                                <div className="mr-2 w-2 rounded-full bg-[#C9A254] group-hover:bg-white"></div>
                                <p className="text-subtitle-sm md:font-light lg:text-subtitle-lg lg:font-light">
                                    {t(
                                        'howitwork.placementAndIntegration.description',
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="group gap-4 rounded-lg bg-[#202022] p-4 shadow-lg hover:bg-[#C9A254] lg:items-center">
                            <h2 className="mb-2 text-headline-sm font-medium md:font-medium lg:text-headline-md">
                                {t('howitwork.postPlacementSupport.title')}
                            </h2>
                            <div className="relative flex">
                                <div className="mr-2 w-2 rounded-full bg-[#C9A254] group-hover:bg-white"></div>
                                <p className="text-subtitle-sm md:font-light lg:text-subtitle-lg lg:font-light">
                                    {t(
                                        'howitwork.postPlacementSupport.description',
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="group gap-4 rounded-lg bg-[#202022] p-4 shadow-lg hover:bg-[#C9A254] lg:items-center">
                            <h2 className="mb-2 text-headline-sm font-medium md:font-medium lg:text-headline-md">
                                {t('howitwork.replacementGuarantee.title')}
                            </h2>
                            <div className="relative flex">
                                <div className="mr-2 w-2 rounded-full bg-[#C9A254] group-hover:bg-white"></div>
                                <p className="text-subtitle-sm md:font-light lg:text-subtitle-lg lg:font-light">
                                    {t(
                                        'howitwork.replacementGuarantee.description',
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 lg:mt-0 lg:w-[50%]">
                        <img
                            className="w-full rounded-lg object-cover lg:h-full"
                            src={Howit3}
                            alt=""
                            width="800"
                            height="600"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Howit;
