import React from 'react';
import { useTranslation } from 'react-i18next';

function Iklan() {
    const { t } = useTranslation(); // Menggunakan useTranslation hook

    return (
        <section className="w-full content-center items-center px-[20px] py-[46px] text-center lg:px-[112px] lg:pt-[150px]">
            <div className="flex flex-col justify-between rounded-3xl bg-bg-banner bg-cover bg-center px-8 py-4 text-white lg:flex-row lg:gap-8 lg:text-left">
                <div className="py-4 lg:w-[45%]">
                    <h1 className="text-display-sm font-extrabold antialiased">
                        {t('iklan.title')}
                    </h1>
                </div>
                <div className="py-4 text-subtitle-lg antialiased lg:w-[65%]">
                    <p className="mb-4 font-normal">{t('iklan.description')}</p>
                    <p>{t('iklan.why')}</p>
                    <div className="lg:px-4">
                        <ul className="mb-8 list-inside lg:list-outside lg:list-disc">
                            <li>
                                <span className="font-bold">
                                    {t('iklan.choose1.title')}{' '}
                                </span>
                                {t('iklan.choose1.desc')}
                            </li>
                            <li>
                                <span className="font-bold">
                                    {t('iklan.choose2.title')}{' '}
                                </span>
                                {t('iklan.choose2.desc')}
                            </li>
                            <li>
                                <span className="font-bold">
                                    {t('iklan.choose3.title')}{' '}
                                </span>
                                {t('iklan.choose3.desc')}
                            </li>
                        </ul>
                    </div>

                    <a
                        href="https://wa.me/6282277812400?text=Halo%20Admin,%20saya%20tertarik%20dengan%20keunggulan%20Supplier%20Helper%20yang%20disebutkan%20dalam%20iklan.%20Saya%20ingin%20mengetahui%20lebih%20lanjut%20mengenai%20proses%20pemotongan%20gaji%20satu%20bulan,%20proses%20pendaftaran%20yang%20efisien,%20dan%20akses%20ke%20pembantu%20yang%20terlatih.%20Mohon%20informasinya.%20Terima%20kasih!"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="rounded-lg bg-[#202022] p-4 text-button-lg">
                        {t('iklan.btn')}
                    </a>
                </div>
            </div>
        </section>
    );
}

export default Iklan;
