import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import debounce from 'lodash.debounce';
import { baseApi } from '../utils/constant';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { FaAngleRight } from 'react-icons/fa';

function BlogList() {
    const [blogs, setBlogs] = useState([]);
    const [page, setPage] = useState();
    const [limit, setLimit] = useState(6);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [keyword, setKeyword] = useState('');
    const [query, setQuery] = useState('');
    const [msg, setMsg] = useState('');
    const [expired, setExpired] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        getBlogs();
    }, [page, keyword, limit]);

    const formatTanggal = (createdAt) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(createdAt).toLocaleDateString('id-ID', options);
    };

    const getBlogs = async () => {
        try {
            const response = await axios.get(
                `${baseApi}/blog/blogsList?page=${page}&limit=${limit}`,
            );
            setBlogs(response.data.result);
            setPage(response.data.page);
            setPages(response.data.totalPage);
            setRows(response.data.totalRows);

            if (response.data.result.length === 0) {
                setMsg('Data yang anda cari tidak di temukan.');
            } else {
                setMsg('');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setMsg('Terjadi kesalahan saat mengambil data.');
        }
    };

    const changePage = ({ selected }) => {
        setPage(selected);
    };

    return (
        <div>
            <Navbar />
            <div className="min-h-screen w-full bg-[#19191A] px-[20px] py-20 lg:px-[112px] lg:pb-[32px]">
                <div className="mt-4 flex items-center gap-4 text-subtitle-lg font-medium text-gray-400">
                    <Link to="/" className="text-gray-400 hover:text-[#C9A254]">
                        Supplier Helper
                    </Link>
                    <FaAngleRight />
                    <span className="text-[#C9A254]">Blogs</span>
                </div>
                <div className="mt-8 h-[1px] w-full bg-[#414141]"></div>
                <div className="mt-[24px] flex flex-col gap-4 lg:flex-row">
                    {blogs.length > 0 ? (
                        blogs.map((blog) => (
                            <Link
                                to={`/blogList/detail/${blog.id}`}
                                key={blog.id}
                                className="group flex flex-col rounded-lg bg-[#202022] hover:shadow-cardShadow lg:w-[50%]">
                                <div className="h-[200px] w-full overflow-hidden rounded-lg lg:h-[250px]">
                                    <img
                                        src={blog.url}
                                        alt={blog.title}
                                        loading="lazy"
                                        width={300}
                                        height={200}
                                        className="ttransition-transform h-full w-full transform object-cover object-center duration-300 group-hover:scale-110 group-hover:object-top"
                                    />
                                </div>
                                <div className="px-4 py-3">
                                    <h1 className="text-headline-sm font-semibold text-white">
                                        {blog.title}
                                    </h1>
                                </div>
                                <div className="mt-auto px-4 py-3">
                                    <p>{formatTanggal(blog.createdAt)}</p>
                                </div>
                            </Link>
                        ))
                    ) : (
                        <div className="content-center text-center">
                            <p className="px-4 py-2 text-center text-white">
                                {msg || 'Tidak ada data'}
                            </p>
                        </div>
                    )}
                </div>
                <div className="mt-8 h-[1px] w-full bg-[#414141]"></div>
                <div className="mt-[32px] items-center justify-between">
                    <ReactPaginate
                        className="flex justify-center gap-x-2 rounded-md border border-[#C9A254] p-2"
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel="..."
                        pageCount={pages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={changePage}
                        containerClassName="flex space-x-2"
                        pageClassName="px-4 py-2 rounded-lg cursor-pointer hover:bg-[#C9A254] hover:text-white"
                        previousClassName="px-4 py-2 rounded-lg cursor-pointer"
                        nextClassName="px-4 py-2 rounded-lg cursor-pointer"
                        activeClassName="bg-[#C9A254] text-white"
                        disabledClassName="text-gray-500 cursor-not-allowed"
                    />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default BlogList;
