//const host = 'http://localhost:3001';
const host = 'https://api.supplierhelper.com';

const baseApi = `${host}`;

const subApiUrl = {
    users: 'users',
    helper: 'helper',
    employer: 'employer',
};

export { baseApi, subApiUrl, host };
