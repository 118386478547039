import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { baseApi } from '../utils/constant';
import { useNavigate } from 'react-router-dom';
import NavbarAdmin from '../components/NavbarAdmin';
import { Outlet } from 'react-router-dom';
import Profile from '../assets/howit1.jpg';
import { Helmet } from 'react-helmet-async';

const SidebarItem = ({ label, to, onClick }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <li
            className="left-0 inline-flex h-[50px] w-full items-center justify-center"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            {label === 'Logout' ? (
                <button onClick={onClick} className="relative h-[50px] w-60">
                    <div className="absolute left-0 top-0 h-full w-60">
                        <div className="absolute left-0 top-0 h-[50px] w-60 bg-[#19191A]" />
                        <div className="absolute left-0 top-0 h-[50px] w-60 bg-[#19191A]" />
                        {isHovered && (
                            <>
                                <div className="absolute left-[24px] top-0 h-[50px] w-48 rounded-md bg-[#995300]" />
                                <div className="absolute left-[-5px] top-0 h-[50px] w-[9px] rounded bg-[#995300]" />
                            </>
                        )}
                        <div className="text-sm absolute left-[78px] top-[13px] font-medium tracking-tight text-white">
                            {label}
                        </div>
                    </div>
                </button>
            ) : (
                <NavLink
                    to={to}
                    className={({ isActive }) =>
                        isActive
                            ? 'relative h-[50px] w-60'
                            : 'relative h-[50px] w-60'
                    }>
                    {({ isActive }) => (
                        <div className="absolute left-0 top-0 h-full w-60">
                            <div className="absolute left-0 top-0 h-[50px] w-60 bg-[#19191A]" />
                            <div className="absolute left-0 top-0 h-[50px] w-60 bg-[#19191A]" />
                            {(isHovered || isActive) && (
                                <>
                                    <div className="absolute left-[24px] top-0 h-[50px] w-48 rounded-md bg-[#995300]" />
                                    <div className="absolute left-[-5px] top-0 h-[50px] w-[9px] rounded bg-[#995300]" />
                                </>
                            )}
                            <div className="text-sm absolute left-[78px] top-[13px] font-semibold tracking-tight text-white">
                                {label}
                            </div>
                        </div>
                    )}
                </NavLink>
            )}
        </li>
    );
};

const userInfo = JSON.parse(localStorage.getItem('userInfo'));

function NavSideAdmin() {
    const navigate = useNavigate();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const logoutUser = async () => {
        try {
            await axios.delete(`${baseApi}/users/logout`);
            navigate('/login');
        } catch (error) {
            console.log(error);
        }
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="flex h-screen flex-col bg-[#19191A]">
            <Helmet>
                <title>
                    Supplier Helper - Connecting Workers and Employers
                </title>
                <meta
                    name="description"
                    content="Welcome to Supplier Helper, where we connect employers and workers."
                />
                <meta
                    name="copyright"
                    content="© 2024 Supplier Helper. All rights reserved."
                />
                <meta name="author" content="Supplier Helper Team" />
            </Helmet>
            <div className="fixed top-0 z-10 w-full">
                <NavbarAdmin
                    toggleSidebar={toggleSidebar}
                    isSidebarOpen={isSidebarOpen}
                />
            </div>
            <div className="lg:flex lg:flex-1">
                <div
                    className={`fixed left-0 top-0 z-50 mt-[70px] h-full w-[240px] overflow-y-auto border-r border-[#4A4A4F] bg-[#19191A] transition-transform duration-300 ease-in-out lg:top-[-10px] ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} shadow-sidebarShadow lg:z-0 lg:block lg:translate-x-0`}>
                    <div className="flex h-[70px] content-center items-center justify-center bg-[#202022] lg:hidden">
                        <button
                            onClick={() => navigate('/admin/profile')}
                            className="flex w-auto content-center items-center justify-center rounded-md p-2 align-middle">
                            <div className="flex">
                                <img
                                    src={Profile}
                                    alt=""
                                    className="h-[40px] w-[40px] rounded-full object-cover"
                                />
                                <div className="ml-[16px] block text-left lg:hidden">
                                    <p className="text-subtitle-sm font-bold text-[#FFFFFF]">
                                        {userInfo.username}
                                    </p>
                                    <p className="text-caption font-semibold text-[#FF971D]">
                                        {userInfo.role}
                                    </p>
                                </div>
                            </div>
                        </button>
                    </div>
                    <div className="mt-[32px] w-full text-center">
                        <ul>
                            <SidebarItem
                                label="Dashboard"
                                to="/admin/dashboard"
                            />
                            <SidebarItem label="Helpers" to="/admin/helpers" />
                            <SidebarItem
                                label="Employers"
                                to="/admin/employer"
                            />
                            <SidebarItem label="Blogs" to="/admin/blogs" />
                            {/* Tambahkan elemen SidebarItem lainnya di sini */}
                        </ul>
                        <div className="my-[16px] h-[1px] w-full bg-[#4A4A4F]"></div>
                        <ul>
                            <SidebarItem label="Logout" onClick={logoutUser} />
                        </ul>
                    </div>
                </div>

                {/* Konten utama di sebelah kanan */}
                <div className="w-full overflow-y-auto bg-[#19191A] lg:ml-[240px]">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default NavSideAdmin;
