import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { baseApi } from '../utils/constant';
import axios from 'axios';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';
import { FaRegMoneyBillAlt, FaAngleRight } from 'react-icons/fa';

function BlogDetail() {
    const [idBlog, setIdBlog] = useState('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [preview, setPreview] = useState('');

    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            await getBlogById(id);
        };
        fetchData();
    }, [id]);

    const getBlogById = async (id) => {
        const response = await axios.get(`${baseApi}/blog/blogs/${id}`);
        setIdBlog(response.data.id);
        setTitle(response.data.title);
        setContent(response.data.content);
        setPreview(response.data.url);
    };

    return (
        <div>
            <Navbar />
            <div className="min-h-screen w-full bg-[#19191A] px-[20px] py-20 lg:px-[112px] lg:pb-[32px]">
                <div className="mt-4 flex flex-row flex-wrap items-center gap-4 text-subtitle-lg font-medium text-gray-400">
                    <Link to="/" className="text-gray-400 hover:text-[#C9A254]">
                        Supplier Helper
                    </Link>
                    <FaAngleRight />
                    <Link
                        to="/blogList"
                        className="text-gray-400 hover:text-[#C9A254]">
                        Blog List
                    </Link>
                    <FaAngleRight />
                    <span className="text-[#C9A254]">{title}</span>
                </div>
                <div className="my-8 h-[1px] w-full bg-[#414141]"></div>
                <div className="flex flex-col rounded-lg bg-[#202022] px-4 py-3">
                    <div className="mt-6">
                        <h1 className="text-headline-md text-white">{title}</h1>
                    </div>
                    <div className="my-4 h-[500px] w-full overflow-hidden rounded-lg lg:h-[600px]">
                        <img
                            src={preview}
                            alt={title}
                            loading="lazy"
                            width={300}
                            height={200}
                            className="ttransition-transform h-full w-full transform object-cover object-center duration-300 group-hover:scale-110 group-hover:object-top"
                        />
                    </div>
                    <div className="my-8 h-[1px] w-full bg-[#414141]"></div>
                    <div className="mb-6">
                        <p className="indent-8 text-body-lg text-white">
                            {content}
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default BlogDetail;
