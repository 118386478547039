import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { baseApi } from '../utils/constant';
import axios from 'axios';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';
import { FaRegMoneyBillAlt, FaAngleRight } from 'react-icons/fa';

function HelperDetail() {
    const [idHelper, setIdHelper] = useState('');
    const [nama, setNama] = useState('');
    const [alamat, setAlamat] = useState('');
    const [negara, setNegara] = useState('');
    const [tanggalLahir, setTanggalLahir] = useState('');
    const [statusPernikahan, setStatusPernikahan] = useState('');
    const [jumlahAnak, setJumlahAnak] = useState('');
    const [jumlahSaudara, setJumlahSaudara] = useState('');
    const [agama, setAgama] = useState('');
    const [jenisKelamin, setJenisKelamin] = useState('');
    const [tinggiBadan, setTinggiBadan] = useState('');
    const [beratBadan, setBeratBadan] = useState('');
    const [pendidikan, setPendidikan] = useState('');
    const [alergi, setAlergi] = useState('');
    const [tato, setTato] = useState('');
    const [pengalaman, setPengalaman] = useState('');
    const [deskripsi, setDeskripsi] = useState('');
    const [kemampuanBahasa, setKemampuanBahasa] = useState('');
    const [kemampuanMasak, setKemampuanMasak] = useState('');
    const [preview, setPreview] = useState('');
    const [value, setValue] = useState('');
    const form = useRef(); // Gunakan useRef untuk referensi form
    const { t } = useTranslation(); // Menggunakan useTranslation hook

    const { id } = useParams();
    const MySwal = withReactContent(Swal);

    useEffect(() => {
        const fetchData = async () => {
            await getHelperById(id);
        };
        fetchData();
    }, [id]);

    const getHelperById = async (id) => {
        const response = await axios.get(`${baseApi}/helper/helpers/${id}`);
        setIdHelper(response.data.id);
        setNama(response.data.nama);
        setNegara(response.data.negara);
        setAlamat(response.data.alamat);
        setStatusPernikahan(response.data.status_pernikahan);
        setJumlahAnak(response.data.jumlah_anak);
        setJumlahSaudara(response.data.jumlah_saudara);
        setTanggalLahir(response.data.tanggal_lahir);
        setAgama(response.data.agama);
        setJenisKelamin(response.data.jenis_kelamin);
        setTinggiBadan(response.data.tinggi_badan);
        setBeratBadan(response.data.berat_badan);
        setPendidikan(response.data.pendidikan);
        setAlergi(response.data.alergi);
        setTato(response.data.tato);
        setPengalaman(response.data.pengalaman);
        setDeskripsi(response.data.deskripsi);
        setKemampuanBahasa(response.data.kemampuan_bahasa);
        setKemampuanMasak(response.data.kemampuan_masak);
        setPreview(response.data.url);
    };

    const formatTanggalLahir = (tanggal) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(tanggal).toLocaleDateString('id-ID', options);
    };

    const calculateAge = (birthDate) => {
        const today = new Date();
        const birthDateObj = new Date(birthDate);
        let age = today.getFullYear() - birthDateObj.getFullYear();
        const monthDifference = today.getMonth() - birthDateObj.getMonth();

        if (
            monthDifference < 0 ||
            (monthDifference === 0 && today.getDate() < birthDateObj.getDate())
        ) {
            age--;
        }

        return age;
    };

    return (
        <div>
            <Navbar />
            <div className="min-h-screen w-full bg-[#19191A] px-[20px] py-20 lg:px-[112px] lg:pb-[32px]">
                <div className="mt-4 flex flex-wrap items-center gap-4 text-subtitle-lg font-medium text-gray-400">
                    <Link to="/" className="text-gray-400 hover:text-[#C9A254]">
                        Supplier Helper
                    </Link>
                    <FaAngleRight />
                    <Link
                        to="/helpersList"
                        className="text-gray-400 hover:text-[#C9A254]">
                        Helper List
                    </Link>
                    <FaAngleRight />
                    <span className="text-[#C9A254]">Detail</span>
                </div>
                <div className="my-8 h-[1px] bg-gray-400"></div>
                <div className="rounded-lg bg-[#202022] p-4 lg:p-8">
                    <div className="flex flex-col flex-wrap justify-center gap-4 text-white lg:flex-col lg:justify-start">
                        <div className="flex flex-col items-center gap-4 lg:flex-row">
                            <div>
                                <img
                                    src={preview}
                                    alt=""
                                    width={100}
                                    height={100}
                                    className="h-[100px] w-[100px] rounded-full object-cover"
                                />
                            </div>
                            <div className="mt-auto flex flex-col items-center lg:items-start">
                                <span className="text-headline-sm lg:text-headline-md">
                                    {nama}{' '}
                                    <span className="text-body-lg">
                                        ({calculateAge(tanggalLahir)}{' '}
                                        {t('helpers.card.age')})
                                    </span>
                                </span>
                                <div className="flex gap-2">
                                    <p>{statusPernikahan}</p>
                                    <p>|</p>
                                    <p>
                                        {jumlahAnak} {t('helpers.card.kids')}
                                    </p>
                                    <p>|</p>
                                    <p>
                                        {jumlahSaudara}{' '}
                                        {t('helpers.card.siblings')}
                                    </p>
                                    <p>|</p>
                                    <p>{agama}</p>
                                </div>
                            </div>
                            <Link
                                to={`/bookHelper/${idHelper}`}
                                className="w-full rounded-lg bg-[#C9A254] px-4 py-2 text-center text-button-lg text-white lg:ml-auto lg:mt-auto lg:w-fit">
                                {t('helpers.card.btnBookNow')})
                            </Link>
                        </div>
                        <div className="my-2 h-[1px] w-full bg-gray-400" />
                        <div>
                            <h1 className="text-headline-sm lg:text-headline-md">
                                Informasi Detail
                            </h1>
                            <div className="grid grid-rows-2 lg:grid-cols-2">
                                <div className="mt-2 font-bold lg:mt-0">
                                    <p>
                                        {t('helpers.card.address')} :{' '}
                                        <span className="font-normal capitalize italic">
                                            {alamat}, {negara}
                                        </span>
                                    </p>
                                    <p>
                                        {t('helpers.card.dateofBirth')} :{' '}
                                        <span className="font-normal italic">
                                            {formatTanggalLahir(tanggalLahir)}
                                        </span>
                                    </p>
                                    <p>
                                        {t('helpers.card.heightWeight')} :{' '}
                                        <span className="font-normal italic">
                                            {' '}
                                            {tinggiBadan}/{beratBadan}
                                        </span>
                                    </p>
                                </div>
                                <div className="mt-2 font-bold lg:mt-0">
                                    <p>
                                        {t('helpers.card.education')} :{' '}
                                        <span className="font-normal italic">
                                            {pendidikan}
                                        </span>
                                    </p>
                                    <p>
                                        {t('helpers.card.allergies')} :{' '}
                                        <span className="font-normal italic">
                                            {alergi}
                                        </span>
                                    </p>
                                    <p>
                                        {t('helpers.card.tatoos')} :{' '}
                                        <span className="font-normal italic">
                                            {tato}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-auto h-[1px] w-full bg-gray-400" />
                        <div>
                            <h1 className="text-headline-sm lg:text-headline-md">
                                {t('helpers.card.skills')}
                            </h1>
                            <div className="grid lg:grid-cols-2">
                                <div className="font-bold">
                                    <p>
                                        {' '}
                                        {t(
                                            'helpers.card.languageSkills',
                                        )} :{' '}
                                    </p>
                                    <span className="font-normal italic">
                                        {kemampuanBahasa}
                                    </span>
                                </div>
                                <div className="mt-2 font-bold lg:mt-0">
                                    <p> {t('helpers.card.cookingSkills')} : </p>
                                    <span className="font-normal italic">
                                        {kemampuanMasak}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="mt-auto h-[1px] w-full bg-gray-400" />
                        <div className="">
                            <h1 className="text-headline-sm lg:text-headline-md">
                                {t('helpers.card.experience')}
                            </h1>
                            <div className="grid lg:grid-cols-2">
                                <div className="font-bold">
                                    <p> {t('helpers.card.experience')} : </p>
                                    <span className="font-normal italic">
                                        {pengalaman}
                                    </span>
                                </div>
                                <div className="mt-2 font-bold lg:mt-0">
                                    <p> {t('helpers.card.description')} : </p>
                                    <span className="font-normal italic">
                                        {deskripsi}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default HelperDetail;
