import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import debounce from 'lodash.debounce';
import { baseApi } from '../utils/constant';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { IoSearch, IoLocationSharp } from 'react-icons/io5';
import { LuSendHorizonal } from 'react-icons/lu';
import { GrLanguage } from 'react-icons/gr';
import { FaAngleRight } from 'react-icons/fa';

import Home from '../assets/home.jpg';
import { useTranslation } from 'react-i18next';

function HelperList() {
    const [helpers, setHelpers] = useState([]);
    const [page, setPage] = useState();
    const [limit, setLimit] = useState(12);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [keyword, setKeyword] = useState('');
    const [query, setQuery] = useState('');
    const [msg, setMsg] = useState('');
    const [sortBy, setSortBy] = useState('createdAt'); // Default sorting by 'id'
    const [sortOrder, setSortOrder] = useState('desc'); // Default sorting order 'desc'
    const [expired, setExpired] = useState('');
    const navigate = useNavigate();
    const { t } = useTranslation(); // Menggunakan useTranslation hook

    useEffect(() => {
        getHelpers();
    }, [page, keyword, limit, sortBy, sortOrder]);

    const getHelpers = async () => {
        try {
            const response = await axios.get(
                `${baseApi}/helper/helpersStatus?search_query=${keyword}&page=${page}&limit=${limit}&sort_by=${sortBy}&sort_order=${sortOrder}`,
            );
            setHelpers(response.data.result);
            setPage(response.data.page);
            setPages(response.data.totalPage);
            setRows(response.data.totalRows);

            if (response.data.result.length === 0) {
                setMsg('Data yang anda cari tidak di temukan.');
            } else {
                setMsg('');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setMsg('Terjadi kesalahan saat mengambil data.');
        }
    };

    const handleSearch = debounce((value) => {
        setPage(0);
        setKeyword(value);
    }, 500);

    const handleChange = (e) => {
        setQuery(e.target.value);
        handleSearch(e.target.value);
    };

    const handleItemsPerPageChange = (e) => {
        setLimit(parseInt(e.target.value));
        setPage(0);
    };

    const changePage = ({ selected }) => {
        setPage(selected);
    };

    const formatTanggalLahir = (tanggal) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(tanggal).toLocaleDateString('id-ID', options);
    };

    const calculateAge = (birthDate) => {
        const today = new Date();
        const birthDateObj = new Date(birthDate);
        let age = today.getFullYear() - birthDateObj.getFullYear();
        const monthDifference = today.getMonth() - birthDateObj.getMonth();

        // Jika bulan sekarang kurang dari bulan lahir atau sama dengan tetapi hari sekarang kurang dari hari lahir, kurangi umur dengan 1
        if (
            monthDifference < 0 ||
            (monthDifference === 0 && today.getDate() < birthDateObj.getDate())
        ) {
            age--;
        }

        return age;
    };

    return (
        <div>
            <Navbar />
            <div className="min-h-screen w-full bg-[#19191A] px-[20px] py-20 lg:px-[112px] lg:pb-[32px]">
                <div className="mt-4 flex items-center gap-4 text-subtitle-lg font-medium text-gray-400">
                    <Link to="/" className="text-gray-400 hover:text-[#C9A254]">
                        Supplier Helper
                    </Link>
                    <FaAngleRight />
                    <span className="text-[#C9A254]">Helper List</span>
                </div>

                <div className="bg-repeat-round-4 my-[28px] rounded-lg bg-bgFilter bg-cover">
                    <div className="flex flex-col px-[12px] py-[32px] lg:px-[100px]">
                        <div className="flex h-[40px] w-full items-center rounded-lg bg-[#202022] p-[6px]">
                            <IoSearch size={24} />
                            <input
                                type="text"
                                placeholder={t(
                                    'searchEmployers.placeholderHelper',
                                )}
                                value={query}
                                onChange={handleChange}
                                className="ml-2 w-full border-none bg-[#202022] text-white placeholder-gray-400 outline-none"
                            />
                        </div>
                        <div className="mt-4 flex flex-row gap-2 rounded-full text-white">
                            <div className="content-center rounded-full border border-[#C9A254] bg-[#202022] px-4">
                                <select
                                    onChange={(e) => setSortBy(e.target.value)}
                                    value={sortBy}
                                    className="sm:text-sm w-full border border-transparent bg-[#202022] focus:outline-none">
                                    <option value="createdAt" disabled>
                                        {t('searchEmployers.sortBy.label')}
                                    </option>
                                    <option value="createdAt">
                                        {t(
                                            'searchEmployers.sortBy.options.createdAt',
                                        )}
                                    </option>
                                    <option value="nama">
                                        {t('helpers.card.name')}
                                    </option>
                                    <option value="tanggal_lahir">
                                        {t(
                                            'searchEmployers.sortBy.options.age',
                                        )}
                                    </option>
                                    <option value="kemampuan_bahasa">
                                        {t(
                                            'searchEmployers.sortBy.options.language',
                                        )}
                                    </option>
                                </select>
                            </div>

                            <div className="rounded-full border-[1px] border-[#C9A254] bg-[#202022] p-2">
                                <select
                                    onChange={(e) =>
                                        setSortOrder(e.target.value)
                                    }
                                    value={sortOrder}
                                    className="rounded-full bg-[#202022] focus:outline-none">
                                    <option value="asc">
                                        {t(
                                            'searchEmployers.sortOrder.options.asc',
                                        )}
                                    </option>
                                    <option value="desc">
                                        {t(
                                            'searchEmployers.sortOrder.options.desc',
                                        )}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mb-[24px] flex items-center justify-between gap-4 lg:flex-row">
                    <div>
                        <select
                            value={limit}
                            onChange={handleItemsPerPageChange}
                            className="mr-2 rounded-lg border border-[#C9A254] bg-[#202022] p-2 text-white">
                            <option value={6}>6</option>
                            <option value={12}>12</option>
                            <option value={24}>24</option>
                            <option value={60}>60</option>
                        </select>
                        <span>Data </span>
                    </div>

                    <div>
                        <p className="text-white">{rows} Helper</p>
                    </div>
                </div>
                {/* Card */}
                <div className="grid-row grid gap-6 lg:grid-cols-4">
                    {helpers.length > 0 ? (
                        helpers.map((helper) => (
                            <div
                                key={helper.id}
                                className="flex flex-col justify-center rounded-lg bg-[#202022] px-4 py-3 transition-transform duration-300 hover:shadow-cardShadow hover:lg:scale-105">
                                <div className="mb-4 grid-cols-1 content-center justify-center gap-4">
                                    <div className="grid-row-1 m-auto grid h-20 w-20 overflow-hidden rounded-full">
                                        <img
                                            src={helper.url}
                                            alt={helper.nama}
                                            width={80}
                                            height={80}
                                            className="h-[80px] w-[80px] rounded-full object-cover"
                                        />
                                    </div>
                                    <div className="grid-row-2 mt-2 grid text-center">
                                        <span className="text-body-sm text-white lg:text-body-lg">
                                            {helper.nama
                                                ?.split(' ')
                                                .slice(0, 2)
                                                .join(' ')}
                                        </span>
                                        <span>
                                            {calculateAge(helper.tanggal_lahir)}{' '}
                                            {t('helpers.card.age')}
                                        </span>
                                        <span className="">
                                            {helper.tinggi_badan}(cm){' '}
                                            {/* {t('helpers.card.height')} */}{' '}
                                            {helper.berat_badan}(kg){' '}
                                            {/* {t('helpers.card.weight')} */}
                                        </span>
                                    </div>
                                    <div className="my-2 h-[1px] w-full bg-[#414141]"></div>
                                    <div className="grid-row-2 mb-3 grid items-center">
                                        <h1 className="text-body-sm font-bold text-white">
                                            {t('helpers.card.languageSkills')} :
                                        </h1>
                                        <span className="text-body-sm capitalize">
                                            {helper.kemampuan_bahasa}
                                        </span>
                                    </div>
                                    {/* <div className="my-2 h-[1px] w-full bg-[#414141]"></div> */}
                                    <div className="mt-auto">
                                        <div className="grid-row-2 mb-auto grid items-center">
                                            <h1 className="text-body-sm font-bold text-white">
                                                {t('helpers.card.experience')} :{' '}
                                            </h1>
                                            <span className="line-clamp-2 min-h-[40px] text-body-sm capitalize">
                                                {helper.pengalaman}
                                            </span>
                                        </div>
                                        <div className="grid-row-2 mb-auto mt-3 grid items-center">
                                            <h1 className="text-body-sm font-bold text-white">
                                                {t('helpers.card.description')}{' '}
                                                :{' '}
                                            </h1>
                                            <span className="line-clamp-3 min-h-[60px] text-body-sm">
                                                {helper.deskripsi}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-auto flex flex-col gap-4">
                                    <Link
                                        to={`/helpersList/detail/${helper.id}`}
                                        className="flex w-full justify-center rounded-md bg-[#C9A254] px-[18px] py-[8px] text-white hover:bg-[#f6c359] hover:text-[#202022]">
                                        <span className="text-button-lg">
                                            Detail
                                        </span>
                                    </Link>
                                    <Link
                                        to={`/bookHelper/${helper.id}`}
                                        className="flex w-full items-center justify-center rounded-md bg-[#4A4A4F] px-[18px] py-[8px] text-white hover:bg-[#515151]">
                                        <span className="text-button-lg">
                                            {t('helpers.card.btnBookNow')}
                                        </span>
                                        <LuSendHorizonal className="ml-4" />
                                    </Link>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="content-center text-center">
                            <p
                                colSpan="8"
                                className="px-4 py-2 text-center text-white">
                                {msg || 'Tidak ada data'}
                            </p>
                        </div>
                    )}
                </div>
                <div className="mt-8 h-[1px] w-full bg-[#414141]"></div>
                <div className="mt-[32px] items-center justify-between">
                    <ReactPaginate
                        className="flex justify-center gap-x-2 rounded-md border border-[#C9A254] p-2"
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel="..."
                        pageCount={pages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={changePage}
                        containerClassName="flex space-x-2"
                        pageClassName="px-4 py-2 rounded-lg cursor-pointer hover:bg-[#C9A254] hover:text-white"
                        previousClassName="px-4 py-2 rounded-lg cursor-pointer"
                        nextClassName="px-4 py-2 rounded-lg cursor-pointer"
                        activeClassName="bg-[#C9A254] text-white"
                        disabledClassName="text-gray-500 cursor-not-allowed"
                    />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default HelperList;
