import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import debounce from 'lodash.debounce';

function Debug() {
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [keyword, setKeyword] = useState('');
    const [query, setQuery] = useState('');
    const [msg, setMsg] = useState('');

    useEffect(() => {
        getUsers();
    }, [page, keyword, limit]);

    const getUsers = async () => {
        try {
            const response = await axios.get(
                `http://localhost:3001/helper/helpers?search_query=${keyword}&page=${page}&limit=${limit}`,
            );
            setUsers(response.data.result);
            setPage(response.data.page);
            setPages(response.data.totalPage);
            setRows(response.data.totalRows);

            if (response.data.result.length === 0) {
                setMsg('Nama yang Anda cari tidak ditemukan.');
            } else {
                setMsg('');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setMsg('Terjadi kesalahan saat mengambil data.');
        }
    };

    const changePage = ({ selected }) => {
        setPage(selected);
    };

    const handleSearch = debounce((value) => {
        setPage(0);
        setKeyword(value);
    }, 500);

    const handleChange = (e) => {
        setQuery(e.target.value);
        handleSearch(e.target.value);
    };

    return (
        <div className="container mt-5">
            <div className="columns">
                <div className="column is-centered">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="field has-addons">
                            <div className="control is-expanded">
                                <input
                                    type="text"
                                    className="input"
                                    value={query}
                                    onChange={handleChange}
                                    placeholder="Find something here..."
                                />
                            </div>
                        </div>
                    </form>
                    <table className="is-striped is-bordered is-fullwidth table mt-2">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Nama</th>
                                <th>Alamat</th>
                                <th>Tanggal Lahir</th>
                                <th>Agama</th>
                                <th>Jenis Kelamin</th>
                                <th>Pengalaman</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.length > 0 ? (
                                users.map((user) => (
                                    <tr key={user.id}>
                                        <td>{user.id}</td>
                                        <td>{user.nama}</td>
                                        <td>{user.alamat}</td>
                                        <td>{user.tanggal_lahir}</td>
                                        <td>{user.agama}</td>
                                        <td>{user.jenis_kelamin}</td>
                                        <td>{user.pengalaman}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td
                                        colSpan="7"
                                        className="has-text-centered">
                                        {msg}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <p>
                        Total Rows: {rows} Page: {rows ? page + 1 : 0} of{' '}
                        {pages}
                    </p>
                    <p className="has-text-centered has-text-danger">{msg}</p>
                    <nav
                        className="pagination is-centered"
                        key={rows}
                        role="navigation"
                        aria-label="pagination">
                        <ReactPaginate
                            previousLabel={'< Prev'}
                            nextLabel={'Next >'}
                            pageCount={pages}
                            onPageChange={changePage}
                            containerClassName={'pagination-list'}
                            pageLinkClassName={'pagination-link'}
                            previousLinkClassName={'pagination-previous'}
                            nextLinkClassName={'pagination-next'}
                            activeClassName={'is-current'}
                        />
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default Debug;
