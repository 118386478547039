import React, { useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Home from '../assets/HeroFoto.png';
import BgHome from '../assets/img/BgHome.jpg';
import { Link as ScrollLink } from 'react-scroll';
import ElementHome from '../assets/svg/element home.svg';
import ShapeBg from '../assets/BgShape.png';
import ShapeBg2 from '../assets/Bg_Line2.png';
import { FaArrowRightLong } from 'react-icons/fa6';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';

function Hero() {
    const { t } = useTranslation(); // Menggunakan useTranslation hook

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <section
            style={{ backgroundImage: `url(${BgHome})` }}
            className="relative flex w-full items-center justify-center overflow-hidden bg-cover bg-center px-[20px] pb-[32px] pt-[100px] lg:top-[12px] lg:h-screen lg:px-[112px] lg:pb-[32px]"
            id="hero">
            <div className="z-1 relative flex h-full w-full flex-col items-center gap-4 text-center lg:flex-row lg:justify-between lg:text-left">
                <div
                    className="w-auto"
                    data-aos="zoom-in"
                    data-aos-duration="500">
                    <h1 className="text-display-sm text-[#19191A] md:text-display-md lg:text-display-lg">
                        {t('hero.title')}
                    </h1>
                    <p className="md:text-body-md mt-[12px] text-body-sm text-[#202022] lg:mt-[24px] lg:text-body-lg">
                        {t('hero.description')}
                    </p>
                    <div
                        className="flex flex-row justify-center gap-x-4 lg:justify-normal"
                        data-aos="zoom-in"
                        data-aos-duration="500">
                        <Link
                            to="/helpersList"
                            className="mt-[12px] flex w-auto items-center justify-center rounded-[8px] bg-[#313131] px-3 py-3 text-button-sm text-white hover:bg-[#161616] hover:shadow-md lg:mt-[32px] lg:text-button-lg">
                            <span className="uppercase">
                                {t('navbar.helpers')}
                            </span>
                        </Link>
                        <Link
                            to="/employerList"
                            className="lg:text-body-lg] md:text-body-md mt-[12px] flex w-auto items-center justify-center space-x-2 rounded-[8px] border-2 border-[#313131] px-3 py-3 text-body-sm font-bold text-[#313131] shadow-lg hover:border-[#161616] hover:bg-[#161616] hover:text-white hover:shadow-md lg:mt-[32px]">
                            <span className="uppercase">
                                {t('hero.applyJob')}
                            </span>
                            <FaArrowRightLong />
                        </Link>
                    </div>
                </div>
                <div
                    data-aos="fade-left"
                    data-aos-duration="500"
                    className="m-auto">
                    <img
                        className="z-1 min-w-sm mx-auto mt-[24px] rounded-[16px] object-cover lg:mt-0 lg:w-[800px] lg:max-w-sm"
                        src={Home}
                        alt="Home Image"
                        loading="lazy"
                        width="800"
                        height="600"
                    />
                </div>
            </div>
        </section>
    );
}

export default Hero;
