import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';

function Footer() {
    const { t, i18n } = useTranslation(); // Menggunakan useTranslation hook
    const navigate = useNavigate();
    const location = useLocation();

    const handleScroll = (section) => {
        if (location.pathname !== '/') {
            navigate('/', { replace: true });
            setTimeout(() => {
                document
                    .getElementById(section)
                    ?.scrollIntoView({ behavior: 'smooth' });
            }, 100); // Delay untuk memastikan halaman sudah dimuat
        } else {
            document
                .getElementById(section)
                ?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="bg-gold-gradient px-[20px] py-[46px] text-center lg:px-[112px] lg:py-[75px]">
            <div className="flex flex-col justify-between text-left lg:flex-row">
                <div className="flex w-full flex-col text-white lg:w-[30%]">
                    <div className="mb-4 text-subtitle-sm font-bold lg:text-subtitle-lg lg:font-extrabold">
                        <h1 className="mb-4 text-subtitle-sm font-bold lg:text-subtitle-lg lg:font-extrabold">
                            {t('Footer.title')}
                        </h1>
                        <p className="mt-[20px] text-subtitle-sm">
                            {t('Footer.description')}
                        </p>
                    </div>
                    {/* Bottom Content: Logo */}
                    <div className="mt-4 flex items-center gap-4">
                        <a
                            href="https://www.facebook.com/profile.php?id=61563644873136"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Visit Facebook Profile"
                            className="rounded-full border border-white p-2 hover:bg-[#1877F2]">
                            <FaFacebook size={20} />
                        </a>
                        <a
                            href="https://www.instagram.com/s.helperindonesia/"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Visit Instagram Profile"
                            className="rounded-full border border-white p-2 hover:bg-instagram-gradient">
                            <FaInstagram size={20} color="" />
                        </a>
                        <a
                            href="https://wa.me/6288227565264?text=Halo%20Admin,%20saya%20membutuhkan%20bantuan%20terkait%20informasi%20di%20Supplier%20Helper.%20Saya%20bingung%20mengenai%20proses%20pendaftaran%20dan%20layanan%20yang%20Anda%20tawarkan.%20Mohon%20bimbingannya.%20Terima%20kasih!"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Visit Whatsapp "
                            className="rounded-full border border-white p-2 hover:bg-[#25D366]">
                            <FaWhatsapp size={20} />
                        </a>
                    </div>
                </div>
                <div className="mt-[72px] flex flex-col gap-[42px] lg:mt-0 lg:flex-row lg:gap-[72px]">
                    <div className="flex h-full flex-col text-white lg:w-auto">
                        {/* Align h1 at the top */}
                        <h1 className="text-subtitle-sm font-bold lg:text-subtitle-lg lg:font-extrabold">
                            {t('Footer.company')}
                        </h1>

                        {/* Align ul at the bottom */}
                        <ul className="mt-[20px]">
                            <li className="mb-[12px]">
                                <ScrollLink
                                    to="hero"
                                    spy={true}
                                    smooth={true}
                                    offset={-200}
                                    duration={500}
                                    className="cursor-pointer text-subtitle-sm"
                                    onClick={() => handleScroll('hero')}>
                                    {t('navbar.home')}
                                </ScrollLink>
                            </li>
                            <li className="mb-[12px]">
                                <ScrollLink
                                    to="services"
                                    spy={true}
                                    smooth={true}
                                    offset={-10}
                                    duration={500}
                                    className="cursor-pointer text-subtitle-sm"
                                    onClick={() => handleScroll('hero')}>
                                    {t('navbar.services')}
                                </ScrollLink>
                            </li>
                            <li className="mb-[12px]">
                                <ScrollLink
                                    to="about"
                                    spy={true}
                                    smooth={true}
                                    offset={-100}
                                    duration={500}
                                    className="cursor-pointer text-subtitle-sm"
                                    onClick={() => handleScroll('hero')}>
                                    {t('navbar.about')}
                                </ScrollLink>
                            </li>
                            <li>
                                <ScrollLink
                                    to="howit"
                                    spy={true}
                                    smooth={true}
                                    offset={-100}
                                    duration={500}
                                    className="cursor-pointer text-subtitle-sm"
                                    onClick={() => handleScroll('hero')}>
                                    {t('navbar.howItWorks')}
                                </ScrollLink>
                            </li>
                        </ul>
                    </div>

                    <div className="text-white lg:w-auto">
                        <h1 className="mb-4 text-subtitle-sm font-bold lg:text-subtitle-lg lg:font-extrabold">
                            {t('navbar.services')}
                        </h1>
                        <ul className="mt-[20px] text-subtitle-sm">
                            <li className="mb-[12px]">
                                <Link to="/helpersList">
                                    {t('navbar.helpers')}
                                </Link>
                            </li>
                            <li>
                                <Link to="/employerList">
                                    {t('navbar.employers')}
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="text-white lg:w-auto">
                        <h1 className="mb-4 text-subtitle-sm font-bold lg:text-subtitle-lg lg:font-extrabold">
                            {t('navbar.insights')}
                        </h1>
                        <ul className="mt-[20px] text-subtitle-sm">
                            <li className="mb-[12px]">
                                <ScrollLink
                                    to="blog"
                                    spy={true}
                                    smooth={true}
                                    offset={-100}
                                    duration={500}
                                    className="cursor-pointer text-subtitle-sm"
                                    onClick={() => handleScroll('hero')}>
                                    {t('navbar.blogNews')}
                                </ScrollLink>
                            </li>

                            <li className="mb-[12px]">
                                <ScrollLink
                                    to="testimonial"
                                    spy={true}
                                    smooth={true}
                                    offset={-100}
                                    duration={500}
                                    className="cursor-pointer text-subtitle-sm"
                                    onClick={() => handleScroll('hero')}>
                                    {t('navbar.testimonial')}
                                </ScrollLink>
                            </li>

                            <li className="mb-[12px]">
                                <ScrollLink
                                    to="faq"
                                    spy={true}
                                    smooth={true}
                                    offset={-100}
                                    duration={500}
                                    className="cursor-pointer text-subtitle-sm"
                                    onClick={() => handleScroll('hero')}>
                                    {t('navbar.faq')}
                                </ScrollLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr className="my-[18px]" />
            <span className="text-subtitle-sm text-white">
                ©2024 SupplierHelper
            </span>
        </div>
    );
}

export default Footer;
