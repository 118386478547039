import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import Accordion from '../components/Accordion';

function FAQs() {
    const { t } = useTranslation();

    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <section
            className="relative w-full bg-[#19191A] px-[20px] py-[46px] lg:px-[112px] lg:py-[75px]"
            id="faq">
            <div className="mb-8 text-center text-[#F5F5F5] lg:mb-[32px]">
                <h2
                    className="mb-[8px] text-headline-lg lg:mb-[16px]"
                    data-aos="fade-up"
                    data-aos-offset="150"
                    data-aos-delay="10"
                    data-aos-duration="1000">
                    {t('FAQs.title')}
                </h2>
                <p
                    className="lg:text-headline-sm lg:font-light"
                    data-aos="fade-up"
                    data-aos-offset="100"
                    data-aos-delay="20"
                    data-aos-duration="1000">
                    {t('FAQs.description')}
                </p>
            </div>

            <Accordion
                title={t('FAQs.FAQ1.title')}
                answer={t('FAQs.FAQ1.description')}
            />

            <Accordion
                title={t('FAQs.FAQ2.title')}
                answer={t('FAQs.FAQ2.description')}
            />

            <Accordion
                title={t('FAQs.FAQ3.title')}
                answer={t('FAQs.FAQ3.description')}
            />

            <Accordion
                title={t('FAQs.FAQ4.title')}
                answer={t('FAQs.FAQ4.description')}
            />

            <Accordion
                title={t('FAQs.FAQ5.title')}
                answer={t('FAQs.FAQ5.description')}
            />

            <Accordion
                title={t('FAQs.FAQ6.title')}
                answer={t('FAQs.FAQ6.description')}
            />

            <Accordion
                title={t('FAQs.FAQ7.title')}
                answer={t('FAQs.FAQ7.description')}
            />

            <Accordion
                title={t('FAQs.FAQ8.title')}
                answer={t('FAQs.FAQ8.description')}
            />

            <Accordion
                title={t('FAQs.FAQ9.title')}
                answer={t('FAQs.FAQ9.description')}
            />
        </section>
    );
}

export default FAQs;
