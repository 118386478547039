import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import Icon1 from '../assets/svg/ICON SERVICE 1.svg';
import Icon2 from '../assets/svg/ICON SERVICE 2.svg';
import Icon3 from '../assets/svg/ICON SERVICE 3.svg';
import Icon4 from '../assets/svg/ICON SERVICE 4.svg';
import Icon5 from '../assets/svg/ICON SERVICE 5.svg';
import Shape from '../assets/svg/shape.svg';

function Services() {
    const { t } = useTranslation();

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <section
            className="relative w-full bg-[#19191A] px-[20px] py-[46px] text-white lg:px-[112px] lg:py-[75px]"
            id="services">
            <div className="mb-8 text-center lg:mb-[64px]">
                <h2
                    className="mb-[8px] text-headline-lg lg:mb-[16px]"
                    data-aos="fade-up"
                    data-aos-offset="100"
                    data-aos-delay="10"
                    data-aos-duration="1000">
                    {t('services.title')}
                </h2>
                <p
                    className="text-body-sm font-light lg:px-[48px] lg:text-body-lg lg:font-light"
                    data-aos="fade-up"
                    data-aos-offset="100"
                    data-aos-delay="10"
                    data-aos-duration="1000">
                    {t('services.description')}
                </p>
            </div>

            <div
                className="flex flex-col justify-center gap-4 lg:flex-row lg:flex-wrap lg:gap-[22px]"
                data-aos="fade-up"
                data-aos-offset="0"
                data-aos-delay="20"
                data-aos-duration="2000">
                <div className="rounded-lg bg-[#202022] px-[24px] py-[16px] shadow-lg lg:w-[32%]">
                    <img className="mx-auto w-10" src={Icon1} alt="" />
                    <h2 className="mb-[12px] mt-[18px] text-center text-headline-sm font-bold text-white lg:h-auto lg:text-body-lg lg:font-bold">
                        {t('services.maidRecruitment.title')}
                    </h2>
                    <p className="lg:body-lg text-center text-subtitle-sm font-light text-[#BFBFBF]">
                        {t('services.maidRecruitment.description')}
                    </p>
                </div>

                <div className="rounded-lg bg-[#202022] px-[24px] py-[16px] shadow-lg lg:w-[32%]">
                    <img className="mx-auto w-10" src={Icon2} alt="" />
                    <h2 className="mb-[12px] mt-[18px] text-center text-headline-sm font-bold text-white lg:h-auto lg:text-body-lg lg:font-bold">
                        {t('services.trainingPrograms.title')}
                    </h2>
                    <p className="lg:body-lg text-center text-subtitle-sm font-light text-[#BFBFBF]">
                        {t('services.trainingPrograms.description')}
                    </p>
                </div>

                <div className="rounded-lg bg-[#202022] px-[24px] py-[16px] shadow-lg lg:w-[32%]">
                    <img className="mx-auto w-10" src={Icon3} alt="" />
                    <h2 className="mb-[12px] mt-[18px] text-center text-headline-sm font-bold text-white lg:h-auto lg:text-body-lg lg:font-bold">
                        {t('services.consultationServices.title')}
                    </h2>
                    <p className="lg:body-lg text-center text-subtitle-sm font-light text-[#BFBFBF]">
                        {t('services.consultationServices.description')}
                    </p>
                </div>

                <div
                    className="rounded-lg bg-[#202022] px-[24px] py-[16px] shadow-lg lg:w-[32%]"
                    data-aos="fade-up"
                    data-aos-offset="0"
                    data-aos-delay="10"
                    data-aos-duration="2000">
                    <img className="mx-auto w-10" src={Icon4} alt="" />
                    <h2 className="mb-[12px] mt-[18px] text-center text-headline-sm font-bold text-white lg:h-auto lg:text-body-lg lg:font-bold">
                        {t('services.documentationAssistance.title')}
                    </h2>
                    <p className="lg:body-lg text-center text-subtitle-sm font-light text-[#BFBFBF]">
                        {t('services.documentationAssistance.description')}
                    </p>
                </div>

                <div
                    className="rounded-lg bg-[#202022] px-[24px] py-[16px] shadow-lg lg:w-[32%]"
                    data-aos="fade-up"
                    data-aos-offset="0"
                    data-aos-delay="10"
                    data-aos-duration="2000">
                    <img className="mx-auto w-10" src={Icon5} alt="" />
                    <h2 className="mb-[12px] mt-[18px] text-center text-headline-sm font-bold text-white lg:h-auto lg:text-body-lg lg:font-bold">
                        {t('services.culturalOrientation.title')}
                    </h2>
                    <p className="lg:body-lg text-center text-subtitle-sm font-light text-[#BFBFBF]">
                        {t('services.culturalOrientation.description')}
                    </p>
                </div>
            </div>
        </section>
    );
}

export default Services;
