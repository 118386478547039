import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { baseApi } from '../utils/constant';
import { LuSendHorizonal } from 'react-icons/lu';
import { IoSearch } from 'react-icons/io5';
import { IoLocationOutline, IoTimeOutline, IoCut } from 'react-icons/io5';
import { FaRegMoneyBillAlt, FaAngleRight, FaCalendar } from 'react-icons/fa';
import { FaRegCalendarAlt } from 'react-icons/fa';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import debounce from 'lodash.debounce';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';

function EmployerList() {
    const [employers, setEmployers] = useState([]);
    const [page, setPage] = useState();
    const [limit, setLimit] = useState(12);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [keyword, setKeyword] = useState('');
    const [query, setQuery] = useState('');
    const [msg, setMsg] = useState('');
    const [expired, setExpired] = useState('');
    const navigate = useNavigate();
    const [sortBy, setSortBy] = useState('createdAt'); // Default sorting by 'id'
    const [sortOrder, setSortOrder] = useState('desc'); // Default sorting order 'desc'
    const { t } = useTranslation(); // Menggunakan useTranslation hook

    useEffect(() => {
        getEmployers();
    }, [page, keyword, limit, sortBy, sortOrder]);

    const hitungPosting = (createdAt) => {
        const createdAtDate = new Date(createdAt);
        const currentDate = new Date();
        const diffTime = Math.abs(currentDate - createdAtDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    };

    const getEmployers = async () => {
        try {
            const response = await axios.get(
                `${baseApi}/employer/employersList?search_query=${keyword}&page=${page}&limit=${limit}&sort_by=${sortBy}&sort_order=${sortOrder}`,
            );

            // setEmployers(employersWithDays); // Set data dengan tambahan daysSinceAdded
            setEmployers(response.data.result);
            setPage(response.data.page);
            setPages(response.data.totalPage);
            setRows(response.data.totalRows);

            if (response.data.result.length === 0) {
                setMsg('Data yang Anda cari tidak ditemukan.');
            } else {
                setMsg('');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setMsg('Terjadi kesalahan saat mengambil data.');
        }
    };

    const handleViewClick = (employer) => {
        const MySwal = withReactContent(Swal);

        MySwal.fire({
            title: '',
            html: (
                <div className="text-left">
                    <div className="flex flex-col lg:flex-row lg:items-center lg:text-left">
                        <div className="ml-0 mt-4 w-[70%] flex-col flex-wrap content-center lg:-mt-0">
                            <p className="mb-2 text-headline-md font-bold uppercase">
                                {t('employers.card.job')} : {employer.pekerjaan}
                            </p>
                            <p className="mb-2 text-body-base font-light lg:whitespace-normal">
                                {t('employers.card.placement')} :{' '}
                                {employer.kids}
                            </p>
                            <div className="mb-2 flex items-center">
                                <IoLocationOutline className="mr-2 fill-white" />
                                <span className="text-body-sm font-light lg:whitespace-normal lg:text-body-base">
                                    {employer.alamat}
                                </span>
                            </div>
                            <div className="mb-2 flex items-center">
                                <FaRegMoneyBillAlt className="mr-2 fill-white" />
                                <span className="whitespace-normal text-body-base font-light">
                                    $ {employer.gaji}{' '}
                                    {t('employers.card.perMonth')}
                                </span>
                            </div>
                            <div className="mb-2 flex items-center">
                                <IoCut className="mr-2 fill-white" />
                                <span className="whitespace-normal text-body-base font-light">
                                    {employer.potongan_gaji}{' '}
                                    {t('employers.card.perMonth')}
                                </span>
                            </div>
                            <div className="mb-2 flex items-center">
                                <FaRegCalendarAlt className="mr-2 fill-white" />
                                <span className="whitespace-normal text-body-base font-light">
                                    {employer.jumlah_off}{' '}
                                    {t('employers.card.daysOff')}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="my-4 h-[1px] w-full bg-[#414141]"></div>
                    <div className="mt-4 flex flex-col lg:flex-row lg:justify-around">
                        <div className="flex flex-col flex-wrap lg:w-[40%]">
                            <p className="text-body-lg text-white">
                                {t('employers.card.criteria')} :
                            </p>
                            <span className="text-[14px] font-normal text-[#BFBFBF]">
                                {employer.kriteria}
                            </span>
                        </div>
                        <div className="mt-2 lg:mt-0 lg:w-[40%]">
                            <p className="text-body-lg text-white">
                                {t('employers.card.qualifications')} :
                            </p>
                            <p className="text-[14px] font-normal text-[#BFBFBF]">
                                {employer.kualifikasi_helper}
                            </p>
                        </div>
                    </div>
                    <div className="my-4 h-[1px] w-full bg-[#414141]"></div>
                    <p className="text-headline-md font-bold uppercase">
                        {t('employers.card.Profile')}
                    </p>
                    <p className="mb-2 whitespace-normal text-body-base font-light">
                        {t('employers.card.name')} : {employer.nama}
                    </p>
                    <p className="mb-2 whitespace-normal text-body-base font-light">
                        Ras: {employer.ras_majikan}
                    </p>
                    <div className="mt-4 flex flex-col flex-wrap items-start gap-2 text-left capitalize text-[#BFBFBF] lg:flex-row lg:flex-nowrap lg:justify-around">
                        <div>
                            <p className="text-body-lg text-white">
                                {t('employers.card.houseType')}
                            </p>
                            <p className="text-[14px] font-normal">
                                {employer.tipe_rumah}
                            </p>
                        </div>
                        <div className="h-auto w-[2px] bg-[#BFBFBF]"></div>
                        <div>
                            <p className="text-body-lg text-white">
                                {t('employers.card.occupants')}
                            </p>
                            <p className="text-[14px] font-normal">
                                {employer.penghuni}
                            </p>
                        </div>
                        <div className="mx-[18px] w-[2px] bg-[#BFBFBF]"></div>
                        {/* <div>
                            <p className="text-body-lg text-white">
                                {t('employers.card.daysOff')}
                            </p>
                            <p className="text-[14px] font-normal">
                                {employer.jumlah_off}
                            </p>
                        </div> */}
                    </div>
                </div>
            ),
            showCloseButton: true,
            showConfirmButton: false,
            customClass: {
                popup: 'custom-popup',
            },
            width: '800px',
        });
    };

    const handleSearch = debounce((value) => {
        setPage(0);
        setKeyword(value);
    }, 500);

    const handleChange = (e) => {
        setQuery(e.target.value);
        handleSearch(e.target.value);
    };

    const handleItemsPerPageChange = (e) => {
        setLimit(parseInt(e.target.value));
        setPage(0);
    };

    const changePage = ({ selected }) => {
        setPage(selected);
    };

    return (
        <div>
            <Navbar />
            <div className="min-h-screen w-full bg-[#19191A] px-[20px] py-20 lg:px-[112px] lg:pb-[32px]">
                <div className="mt-4 flex items-center gap-4 text-subtitle-lg font-medium text-gray-400">
                    <Link to="/" className="text-gray-400 hover:text-[#C9A254]">
                        Supplier Helper
                    </Link>
                    <FaAngleRight />
                    <span className="text-[#C9A254]">Employer List</span>
                </div>
                <div className="bg-repeat-round-4 my-[28px] rounded-lg bg-bgFilter bg-cover">
                    <div className="flex flex-col px-[12px] py-[32px] lg:px-[100px]">
                        <div className="flex h-[40px] w-full items-center rounded-lg bg-[#202022] p-[6px]">
                            <IoSearch size={24} />
                            <input
                                type="text"
                                placeholder={t('searchEmployers.placeholder')}
                                value={query}
                                onChange={handleChange}
                                className="ml-2 w-full border-none bg-[#202022] text-white placeholder-gray-400 outline-none"
                            />
                        </div>
                        <div className="mt-4 flex flex-row gap-2 rounded-full text-white">
                            <div className="content-center rounded-full border border-[#C9A254] bg-[#202022] px-4">
                                <select
                                    onChange={(e) => setSortBy(e.target.value)}
                                    value={sortBy}
                                    className="sm:text-sm w-full border border-transparent bg-[#202022] focus:outline-none">
                                    <option value="createdAt" disabled>
                                        {t('searchEmployers.sortBy.label')}
                                    </option>
                                    <option value="createdAt">
                                        {t(
                                            'searchEmployers.sortBy.options.createdAt',
                                        )}
                                    </option>
                                    <option value="pekerjaan">
                                        {t(
                                            'searchEmployers.sortBy.options.job',
                                        )}
                                    </option>
                                    <option value="gaji">
                                        {t(
                                            'searchEmployers.sortBy.options.salary',
                                        )}
                                    </option>
                                    <option value="jumlah_off">
                                        {t(
                                            'searchEmployers.sortBy.options.offDays',
                                        )}
                                    </option>
                                </select>
                            </div>

                            <div className="rounded-full border-[1px] border-[#C9A254] bg-[#202022] p-2">
                                <select
                                    onChange={(e) =>
                                        setSortOrder(e.target.value)
                                    }
                                    value={sortOrder}
                                    className="rounded-full bg-[#202022] focus:outline-none">
                                    <option value="asc">
                                        {t(
                                            'searchEmployers.sortOrder.options.asc',
                                        )}
                                    </option>
                                    <option value="desc">
                                        {t(
                                            'searchEmployers.sortOrder.options.desc',
                                        )}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mb-[24px] flex items-center justify-between gap-4 lg:flex-row">
                    <div>
                        <select
                            value={limit}
                            onChange={handleItemsPerPageChange}
                            className="mr-2 rounded-lg border border-[#C9A254] bg-[#202022] p-2 text-white">
                            <option value={6}>6</option>
                            <option value={12}>12</option>
                            <option value={24}>24</option>
                            <option value={60}>60</option>
                        </select>
                        <span>Data </span>
                    </div>

                    <div>
                        <p className="text-white">{rows} Job</p>
                    </div>
                </div>

                {/* Card */}
                <div className="grid w-full grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                    {employers.length > 0 ? (
                        employers.map((employer) => (
                            <div
                                key={employer.id}
                                className="flex flex-col justify-between rounded-lg bg-[#202022] p-4 transition-transform duration-300 hover:scale-105 hover:shadow-cardShadow">
                                {/* Konten diatur dengan Grid */}
                                <div className="grid grid-cols-1 gap-4">
                                    {/* Baris Pekerjaan */}
                                    <div className="grid-row-2 grid">
                                        <p className="text-base font-bold text-white">
                                            {t('employers.card.job')}: {''}
                                            {employer.pekerjaan}
                                        </p>
                                        <p className="text-base font-normal text-white">
                                            {employer.negara}
                                        </p>
                                    </div>
                                    <div>
                                        <div className="h-[1px] w-full bg-[#414141]"></div>{' '}
                                        {/* Baris Penghuni */}
                                        {/* <div className="grid-row-2 grid">
                                            <p className="text-lg font-bold text-white">
                                                {t('employers.card.occupants')}:{' '}
                                                <span className="text-base font-normal text-white">
                                                    {employer.penghuni}
                                                </span>
                                            </p>
                                        </div> */}
                                    </div>

                                    {/* <div className="h-[1px] w-full bg-[#414141]"></div> */}
                                    {/* Baris Ras */}
                                    <div className="grid-row-2 grid">
                                        <p className="text-lg font-bold text-white">
                                            Ras:{' '}
                                            <span className="text-base font-normal text-[#BFBFBF]">
                                                {employer.ras_majikan}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="mt-auto">
                                        {/* Baris Kriteria (dengan min-height) */}
                                        <div className="my-2 min-h-[60px]">
                                            <p className="text-lg font-bold text-white">
                                                {t('employers.card.criteria')}:
                                            </p>
                                            <p className="text-sm line-clamp-1 h-[30px] font-normal text-[#BFBFBF]">
                                                {employer.kriteria}
                                            </p>
                                        </div>
                                        {/* Baris Kualifikasi (dengan min-height) */}
                                        <div className="min-h-[60px]">
                                            <p className="text-lg font-bold text-white">
                                                {t(
                                                    'employers.card.qualifications',
                                                )}
                                                :
                                            </p>
                                            <p className="text-sm line-clamp-2 h-[50px] font-normal text-[#BFBFBF]">
                                                {employer.kualifikasi_helper}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="mt-auto">
                                        {/* Baris Gaji */}
                                        <div className="my-4">
                                            <p className="text-base font-light text-white">
                                                $ {employer.gaji}{' '}
                                                {t('employers.card.perMonth')}
                                            </p>
                                        </div>
                                        {/* Baris Jumlah Hari Libur */}
                                        <div className="flex items-center">
                                            <FaRegCalendarAlt className="mr-2 fill-white" />
                                            <p className="text-base font-light text-white">
                                                {employer.jumlah_off}{' '}
                                                {t('employers.card.daysOff')}
                                            </p>
                                        </div>
                                        {/* Baris Potongan Gaji */}
                                        <div className="my-4 flex items-center">
                                            <IoCut className="mr-2 fill-white" />
                                            <span className="text-base font-light text-white">
                                                {employer.potongan_gaji}{' '}
                                                {t('employers.card.perMonth')}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                {/* Tombol dan Info Waktu */}
                                <div className="mt-auto">
                                    <div className="my-4 flex items-center">
                                        <button
                                            className="mr-2 flex w-auto rounded-md bg-[#C9A254] px-[18px] py-[8px] text-white hover:bg-[#f6c359] hover:text-[#202022] focus:outline-none focus:ring-1 focus:ring-[#F3B129] focus:ring-offset-2"
                                            title="View"
                                            onClick={() =>
                                                handleViewClick(employer)
                                            }>
                                            <span className="text-button-lg">
                                                {t('employers.card.btnDetail')}
                                            </span>
                                        </button>
                                        <Link
                                            to={`/employer/ApplyJob/${employer.id}`}
                                            className="flex w-full items-center justify-center rounded-md bg-[#4A4A4F] px-[18px] py-[8px] text-white hover:bg-[#515151]">
                                            <span className="text-button-lg">
                                                {t('employers.card.btnApply')}
                                            </span>
                                            <LuSendHorizonal className="ml-4" />
                                        </Link>
                                    </div>

                                    <div className="flex items-center gap-2">
                                        <IoTimeOutline />
                                        <p className="text-left">
                                            {hitungPosting(employer.createdAt)}{' '}
                                            {t('employers.card.infoTime')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="flex items-center justify-center text-white">
                            <p>{msg || 'Tidak ada data'}</p>
                        </div>
                    )}
                </div>

                <div className="mt-8 h-[1px] w-full bg-[#414141]"></div>
                <div className="mt-[32px] items-center justify-between">
                    <ReactPaginate
                        className="flex justify-center gap-x-2 rounded-md border border-[#C9A254] p-2"
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel="..."
                        pageCount={pages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={changePage}
                        containerClassName="flex space-x-2"
                        pageClassName="px-4 py-2 rounded-lg cursor-pointer hover:bg-[#C9A254] hover:text-white"
                        previousClassName="px-4 py-2 rounded-lg cursor-pointer"
                        nextClassName="px-4 py-2 rounded-lg cursor-pointer"
                        activeClassName="bg-[#C9A254] text-white"
                        disabledClassName="text-gray-500 cursor-not-allowed"
                    />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default EmployerList;
