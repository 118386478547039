import React, { useState } from 'react';

function Accordion({ title, answer }) {
    const [accordionOpen, setAccordionOpen] = useState(false);

    return (
        <div
            data-aos="fade-up"
            data-aos-offset="100"
            data-aos-delay="20"
            data-aos-duration="1000"
            className="mb-[24px] rounded-lg bg-[#202022] p-4 text-[#F5F5F5]">
            <button
                onClick={() => setAccordionOpen(!accordionOpen)}
                className="mt-4 flex w-full items-center justify-between text-left">
                <span className="text-body-sm lg:text-body-lg lg:font-bold">
                    {title}
                </span>
                {/* {accordionOpen ? <span>-</span> : <span>+</span>} */}
                <svg
                    className="ml-8 shrink-0 fill-white"
                    width="16"
                    height="16"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect
                        y="7"
                        width="16"
                        height="2"
                        rx="1"
                        className={`origin-center transform transition duration-200 ease-out ${
                            accordionOpen && '!rotate-180'
                        }`}
                    />
                    <rect
                        y="7"
                        width="16"
                        height="2"
                        rx="1"
                        className={`origin-center rotate-90 transform transition duration-200 ease-out ${
                            accordionOpen && '!rotate-180'
                        }`}
                    />
                </svg>
            </button>
            <div
                className={`mt-4 grid overflow-hidden transition-all duration-300 ease-in-out ${
                    accordionOpen
                        ? 'grid-rows-[1fr] opacity-100'
                        : 'grid-rows-[0fr] opacity-0'
                }`}>
                <div className="overflow-hidden text-subtitle-sm font-light text-[#c5c5c5] lg:text-subtitle-lg lg:font-light">
                    {answer}
                </div>
            </div>
        </div>
    );
}

export default Accordion;
