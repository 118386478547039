import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link as ScrollLink } from 'react-scroll';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import Logo from '../assets/svg/logo SH.svg';
import LogoWa from '../assets/svg/whatsapp-social-media-svgrepo-com.svg';

const Navbar = () => {
    const [nav, setNav] = useState(true);
    const [bgColor, setBgColor] = useState(false);
    const [hoverColor, setHoverColor] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(null);
    const [activeLink, setActiveLink] = useState();

    const navigate = useNavigate();
    const location = useLocation();

    const { t, i18n } = useTranslation(); // Menggunakan useTranslation hook

    // Fungsi untuk mengganti bahasa
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const flagUrl = (lang) => {
        return lang === 'id'
            ? 'https://flagcdn.com/w80/id.png' // Gambar bendera Indonesia HD
            : 'https://flagcdn.com/w80/us.png'; // Gambar bendera Amerika HD
    };

    const handleMouseEnter = (dropdown) => {
        setOpenDropdown(dropdown);
    };

    const handleMouseLeave = () => {
        setOpenDropdown(null);
    };

    const toggleNav = () => {
        setNav(!nav);
    };

    const changeBackground = () => {
        if (window.scrollY >= 50) {
            setBgColor('lg:bg-[#313131] lg:text-[#FF971D]');
            setHoverColor('hover:text-[#C9A254]');
        } else {
            setBgColor('lg:bg-navbar');
            setHoverColor('hover:text-[#C9A254] ');
        }
    };

    const handleScroll = (section) => {
        setActiveLink(section);
        if (location.pathname !== '/') {
            navigate('/', { replace: true });
            setTimeout(() => {
                document
                    .getElementById(section)
                    ?.scrollIntoView({ behavior: 'smooth' });
            }, 100); // Delay untuk memastikan halaman sudah dimuat
        } else {
            document
                .getElementById(section)
                ?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeBackground);

        return () => {
            window.removeEventListener('scroll', changeBackground);
        };
    }, []);

    return (
        <nav
            className={`h-18 fixed left-0 right-0 z-10 mx-auto flex items-center justify-between bg-[#19191A] px-[20px] text-[#F5F5F5] shadow-sidebarShadow transition-colors duration-300 lg:fixed lg:left-0 lg:right-0 lg:top-0 lg:z-10 lg:px-[112px]`}>
            <div className="items-center lg:flex lg:w-full lg:justify-between">
                <a
                    href="/"
                    className="text-cente flex content-center items-start justify-center py-4 text-[24px] font-bold">
                    {/* <img src={Logo} alt="" className="w-[32px] items-center" /> */}
                    <span className="text-[#C9A254]">Supplier </span>
                    Helper.
                </a>

                <ul className="hidden lg:flex">
                    <li className={`cursor-pointer p-4 ${hoverColor}`}>
                        <ScrollLink
                            to="hero"
                            spy={true}
                            smooth={true}
                            offset={-200}
                            duration={500}
                            className={`cursor-pointer ${activeLink === 'hero' ? 'active' : ''}`}
                            onClick={() => handleScroll('hero')}>
                            {t('navbar.home')}
                        </ScrollLink>
                    </li>

                    <li
                        className={`group relative cursor-pointer p-4 ${hoverColor}`}
                        onMouseEnter={() => handleMouseEnter('services')}
                        onMouseLeave={handleMouseLeave}>
                        <ScrollLink
                            to="services"
                            spy={true}
                            smooth={true}
                            offset={-50}
                            duration={500}
                            className={`cursor-pointer ${activeLink === 'services' ? 'active' : ''}`}
                            onClick={() => handleScroll('services')}>
                            <span className="flex content-center items-center justify-center gap-2">
                                <span>{t('navbar.services')}</span>
                                <div className="">
                                    {openDropdown === 'services' ? (
                                        <FaChevronUp />
                                    ) : (
                                        <FaChevronDown />
                                    )}
                                </div>
                            </span>
                        </ScrollLink>
                        {openDropdown === 'services' && (
                            <ul className="bg-navbar absolute left-0 mt-2 w-40 rounded-lg text-body-sm shadow-lg">
                                <li className="hover:bg-utama p-4 hover:rounded-lg hover:bg-gray-100 hover:text-black">
                                    <ScrollLink
                                        to="howit"
                                        spy={true}
                                        smooth={true}
                                        offset={-50}
                                        duration={500}
                                        className={`cursor-pointer ${activeLink === 'howit' ? 'active' : ''}`}
                                        onClick={() => handleScroll('howit')}>
                                        {t('navbar.howItWorks')}
                                    </ScrollLink>
                                </li>
                                <li className="hover:bg-utama p-4 hover:rounded-lg hover:bg-gray-100 hover:text-black">
                                    <Link to="/helpersList">
                                        {t('navbar.helpers')}
                                    </Link>
                                </li>
                                <li className="hover:bg-utama p-4 hover:rounded-lg hover:bg-gray-100 hover:text-black">
                                    <Link to="/employerList">
                                        {t('navbar.employers')}
                                    </Link>
                                </li>
                            </ul>
                        )}
                    </li>

                    <li className={`cursor-pointer p-4 ${hoverColor}`}>
                        <ScrollLink
                            to="about"
                            spy={true}
                            smooth={true}
                            offset={-50}
                            duration={500}
                            className={`cursor-pointer ${activeLink === 'about' ? 'active' : ''}`}
                            onClick={() => handleScroll('about')}>
                            {t('navbar.about')}
                        </ScrollLink>
                    </li>

                    <li
                        className={`group relative cursor-pointer p-4 ${hoverColor}`}
                        onMouseEnter={() => handleMouseEnter('Insights')}
                        onMouseLeave={handleMouseLeave}>
                        <ScrollLink
                            to=""
                            spy={true}
                            smooth={true}
                            offset={-200}
                            duration={500}
                            activeClass="active"
                            className="cursor-pointer"
                            onClick={() => handleScroll('Insights')}>
                            <span className="flex content-center items-center justify-center gap-2">
                                <span>{t('navbar.insights')}</span>
                                <div className="">
                                    {openDropdown === 'Insights' ? (
                                        <FaChevronUp />
                                    ) : (
                                        <FaChevronDown />
                                    )}
                                </div>
                            </span>
                        </ScrollLink>
                        {openDropdown === 'Insights' && (
                            <ul className="bg-navbar absolute left-0 mt-2 w-40 rounded-lg text-body-sm shadow-lg">
                                <li className="p-4 hover:rounded-lg hover:bg-gray-100 hover:text-black">
                                    <Link
                                        to="/blogList"
                                        className={`cursor-pointer ${activeLink === 'blog' ? 'active' : ''}`}
                                        onClick={() => handleScroll('blog')}>
                                        {t('navbar.blogNews')}
                                    </Link>
                                </li>
                                <li className="p-4 hover:rounded-lg hover:bg-gray-100 hover:text-black">
                                    <ScrollLink
                                        to="testimonial"
                                        spy={true}
                                        smooth={true}
                                        offset={-50}
                                        duration={500}
                                        className={`cursor-pointer ${activeLink === 'testimonial' ? 'active' : ''}`}
                                        onClick={() =>
                                            handleScroll('testimonial')
                                        }>
                                        {t('navbar.testimonial')}
                                    </ScrollLink>
                                </li>
                                <li className="p-4 hover:rounded-lg hover:bg-gray-100 hover:text-black">
                                    <ScrollLink
                                        to="faq"
                                        spy={true}
                                        smooth={true}
                                        offset={-100}
                                        duration={500}
                                        className={`cursor-pointer ${activeLink === 'faq' ? 'active' : ''}`}
                                        onClick={() => handleScroll('faq')}>
                                        {t('navbar.faq')}
                                    </ScrollLink>
                                </li>
                            </ul>
                        )}
                    </li>

                    <li className={`cursor-pointer p-4 ${hoverColor}`}>
                        <ScrollLink
                            to="contact"
                            spy={true}
                            smooth={true}
                            offset={-50}
                            duration={500}
                            className={`cursor-pointer ${activeLink === 'contact' ? 'active' : ''}`}
                            onClick={() => handleScroll('contact')}>
                            {t('navbar.contactUs')}
                        </ScrollLink>
                    </li>
                </ul>

                <div className="hidden items-center lg:flex">
                    <ul className="flex items-center">
                        <li className="flex items-center gap-x-[2px] rounded-full py-1">
                            <img
                                src={flagUrl(i18n.language)}
                                alt={
                                    i18n.language === 'id'
                                        ? 'Indonesia'
                                        : 'United States'
                                }
                                className="border-gold h-4 w-4 rounded-full border object-cover"
                            />
                            <select
                                onChange={(e) => changeLanguage(e.target.value)}
                                aria-label="Select Language"
                                className="rounded-full bg-[#19191A] p-[2px] text-white focus:outline-none"
                                value={i18n.language}>
                                <option
                                    value="en"
                                    className="bg-[#19191A] text-white">
                                    EN
                                </option>
                                <option
                                    value="id"
                                    className="bg-[#19191A] text-white">
                                    ID
                                </option>
                            </select>
                        </li>
                        <li className="ml-[8px] rounded-lg bg-gold-gradient p-3">
                            <Link to="/helpersList" className="cursor-pointer">
                                {t('navbar.btn')}
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="ml-auto flex items-center rounded-full py-1 lg:hidden">
                <img
                    src={flagUrl(i18n.language)}
                    alt={i18n.language === 'id' ? 'Indonesia' : 'United States'}
                    className="border-gold h-4 w-4 rounded-full border object-cover"
                />
                <select
                    onChange={(e) => changeLanguage(e.target.value)}
                    aria-label="Select Language"
                    className="rounded-full bg-[#19191A] p-[2px] text-white focus:outline-none"
                    value={i18n.language}>
                    <option value="en" className="bg-[#19191A] text-white">
                        EN
                    </option>
                    <option value="id" className="bg-[#19191A] text-white">
                        ID
                    </option>
                </select>
            </div>
            <div
                onClick={toggleNav}
                className="ml-2 block cursor-pointer lg:hidden">
                {!nav ? (
                    <AiOutlineClose size={20} />
                ) : (
                    <AiOutlineMenu size={20} />
                )}
            </div>

            <div
                className={
                    !nav
                        ? 'fixed left-0 top-0 h-full w-[60%] bg-[#19191A] shadow-cardShadow duration-500 ease-in-out lg:hidden'
                        : 'fixed left-[-100%] shadow-cardShadow'
                }>
                <h1 className="text-lg m-4 uppercase">
                    <span className="text-[#C9A254]">Supplier </span>
                    <span className="text-white">Helper.</span>
                </h1>

                <ul className="uppercase">
                    <li
                        className={`cursor-pointer p-4 ${hoverColor} border-[#535353]`}>
                        <ScrollLink
                            to="hero"
                            spy={true}
                            smooth={true}
                            offset={-200}
                            duration={500}
                            className={`cursor-pointer ${activeLink === 'hero' ? 'active' : ''}`}
                            onClick={() => handleScroll('hero')}>
                            {t('navbar.home')}
                        </ScrollLink>
                    </li>
                    <li
                        className={`group relative cursor-pointer p-4 ${hoverColor} border-t border-[#535353]`}
                        onMouseEnter={() => handleMouseEnter('services')}
                        onMouseLeave={handleMouseLeave}>
                        <ScrollLink
                            to="services"
                            spy={true}
                            smooth={true}
                            offset={-50}
                            duration={500}
                            className={`cursor-pointer ${activeLink === 'services' ? 'active' : ''}`}
                            onClick={() => handleScroll('services')}>
                            <span className="flex items-center gap-2 text-left">
                                <span>{t('navbar.services')}</span>
                                <div className="">
                                    {openDropdown === 'services' ? (
                                        <FaChevronUp />
                                    ) : (
                                        <FaChevronDown />
                                    )}
                                </div>
                            </span>
                        </ScrollLink>
                        {openDropdown === 'services' && (
                            <ul className="bg-navbar relative left-0 mt-2 w-40 rounded-lg text-body-sm shadow-lg">
                                <li className="hover:bg-utama p-4 hover:rounded-lg hover:bg-gray-100 hover:text-black">
                                    <ScrollLink
                                        to="howit"
                                        spy={true}
                                        smooth={true}
                                        offset={-50}
                                        duration={500}
                                        className={`cursor-pointer ${activeLink === 'howit' ? 'active' : ''}`}
                                        onClick={() => handleScroll('howit')}>
                                        {t('navbar.howItWorks')}
                                    </ScrollLink>
                                </li>
                                <li className="hover:bg-utama p-4 hover:rounded-lg hover:bg-gray-100 hover:text-black">
                                    <Link to="/helpersList">
                                        {t('navbar.helpers')}
                                    </Link>
                                </li>
                                <li className="hover:bg-utama p-4 hover:rounded-lg hover:bg-gray-100 hover:text-black">
                                    <Link to="/employerList">
                                        {t('navbar.employers')}
                                    </Link>
                                </li>
                            </ul>
                        )}
                    </li>

                    <li
                        className={`cursor-pointer p-4 ${hoverColor} border-t border-[#535353]`}>
                        <ScrollLink
                            to="about"
                            spy={true}
                            smooth={true}
                            offset={-50}
                            duration={500}
                            className={`cursor-pointer ${activeLink === 'about' ? 'active' : ''}`}
                            onClick={() => handleScroll('about')}>
                            {t('navbar.about')}
                        </ScrollLink>
                    </li>

                    <li
                        className={`group relative cursor-pointer p-4 ${hoverColor} border-t border-[#535353]`}
                        onMouseEnter={() => handleMouseEnter('Insights')}
                        onMouseLeave={handleMouseLeave}>
                        <ScrollLink
                            to=""
                            spy={true}
                            smooth={true}
                            offset={-200}
                            duration={500}
                            activeClass="active"
                            className="cursor-pointer"
                            onClick={() => handleScroll('Insights')}>
                            <span className="flex items-center gap-2">
                                <span>{t('navbar.insights')}</span>
                                <div className="">
                                    {openDropdown === 'Insights' ? (
                                        <FaChevronUp />
                                    ) : (
                                        <FaChevronDown />
                                    )}
                                </div>
                            </span>
                        </ScrollLink>
                        {openDropdown === 'Insights' && (
                            <ul className="bg-navbar relative left-0 mt-2 w-40 rounded-lg text-body-sm shadow-lg">
                                <li className="p-4 hover:rounded-lg hover:bg-gray-100 hover:text-black">
                                    <Link to="/blogList">
                                        {t('navbar.blogNews')}
                                    </Link>
                                </li>
                                <li className="p-4 hover:rounded-lg hover:bg-gray-100 hover:text-black">
                                    <ScrollLink
                                        to="testimonial"
                                        spy={true}
                                        smooth={true}
                                        offset={-50}
                                        duration={500}
                                        className={`cursor-pointer ${activeLink === 'testimonial' ? 'active' : ''}`}
                                        onClick={() =>
                                            handleScroll('testimonial')
                                        }>
                                        {t('navbar.testimonial')}
                                    </ScrollLink>
                                </li>
                                <li className="p-4 hover:rounded-lg hover:bg-gray-100 hover:text-black">
                                    <ScrollLink
                                        to="faq"
                                        spy={true}
                                        smooth={true}
                                        offset={-100}
                                        duration={500}
                                        className={`cursor-pointer ${activeLink === 'faq' ? 'active' : ''}`}
                                        onClick={() => handleScroll('faq')}>
                                        {t('navbar.faq')}
                                    </ScrollLink>
                                </li>
                            </ul>
                        )}
                    </li>
                </ul>
                <div className="items-center p-4">
                    <ul className="flex flex-col gap-4">
                        <li className="flex items-center gap-x-[2px] rounded-full py-1">
                            <img
                                src={flagUrl(i18n.language)}
                                alt={
                                    i18n.language === 'id'
                                        ? 'Indonesia'
                                        : 'United States'
                                }
                                className="border-gold h-4 w-4 rounded-full border object-cover"
                            />
                            <select
                                onChange={(e) => changeLanguage(e.target.value)}
                                aria-label="Select Language"
                                className="rounded-full bg-[#19191A] p-[2px] text-white focus:outline-none"
                                value={i18n.language}>
                                <option
                                    value="en"
                                    className="bg-[#19191A] text-white">
                                    EN
                                </option>
                                <option
                                    value="id"
                                    className="bg-[#19191A] text-white">
                                    ID
                                </option>
                            </select>
                        </li>
                        <li className="rounded-lg bg-gold-gradient p-3">
                            <Link to="/helpersList" className="cursor-pointer">
                                {t('navbar.btn')}
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
