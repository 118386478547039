import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { IoAddCircle } from 'react-icons/io5';
import { IoSearch } from 'react-icons/io5';
import { FaFilter, FaRegEdit, FaEye, FaRegTrashAlt } from 'react-icons/fa';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import debounce from 'lodash.debounce';
import { baseApi } from '../utils/constant';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import { useLocation } from 'react-router-dom';

function Blog() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [blogs, setBlogs] = useState([]);
    const [token, setToken] = useState('');
    const [page, setPage] = useState();
    const [limit, setLimit] = useState(10);
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [keyword, setKeyword] = useState('');
    const [query, setQuery] = useState('');
    const [msg, setMsg] = useState('');
    const [expired, setExpired] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        refreshToken();
        getBlogs();
    }, [page, keyword, limit]);

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseApi}/users/token`);
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setExpired(decoded.exp);
        } catch (error) {
            navigate('/login');
        }
    };

    const axiosJwt = axios.create();

    axiosJwt.interceptors.request.use(
        async (config) => {
            const currentDate = new Date();
            if (expired < currentDate.getTime() / 1000) {
                const response = await axios.get(`${baseApi}/users/token`);
                config.headers.Authorization = `Bearer ${response.data.accessToken}`;
                setToken(response.data.accessToken);
                const decoded = jwtDecode(response.data.accessToken);
                setExpired(decoded.exp);
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        },
    );

    const getBlogs = async () => {
        try {
            const response = await axiosJwt.get(
                `${baseApi}/blog/blogsList?page=${page}&limit=${limit}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
            setBlogs(response.data.result);
            setPage(response.data.page);
            setPages(response.data.totalPage);
            setRows(response.data.totalRows);

            if (response.data.result.length === 0) {
                setMsg('Data yang Anda cari tidak ditemukan.');
            } else {
                setMsg('');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setMsg('Terjadi kesalahan saat mengambil data.');
        }
    };

    // Function untuk delete helper by ID
    const handleDeleteClick = (id) => {
        Swal.fire({
            title: 'Apakah Anda yakin?',
            text: 'Data yang dihapus tidak dapat dikembalikan!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Ya, hapus!',
            cancelButtonText: 'Batal',
            customClass: {
                popup: 'custom-popup',
            },
            iconColor: '#B24400',
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(id);
            }
        });
    };

    const handleDelete = async (id) => {
        try {
            const response = await axiosJwt.delete(
                `${baseApi}/blog/blogs/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
            // Update state setelah delete
            getBlogs();

            // Tampilkan toast untuk sukses
            Swal.fire({
                icon: 'success',
                title: 'Data berhasil dihapus.',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                customClass: {
                    popup: 'custom-swal', // Menambahkan kelas kustom
                },
                background: 'transparent', // Agar background default tidak mengganggu
                iconColor: '#409900', // Mengubah warna ikon
            });
        } catch (error) {
            console.log(error);

            Swal.fire({
                icon: 'error',
                title: 'Terjadi kesalahan saat menghapus data.',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                customClass: {
                    popup: 'custom-swal',
                },
            });
        }
    };

    const handleSearch = debounce((value) => {
        setPage(0);
        setKeyword(value);
    }, 500);

    const handleChange = (e) => {
        setQuery(e.target.value);
        handleSearch(e.target.value);
    };

    const handleItemsPerPageChange = (e) => {
        setLimit(parseInt(e.target.value));
        setPage(0);
    };

    const changePage = ({ selected }) => {
        setPage(selected);
    };

    return (
        <div className="mt-[70px] p-[32px]">
            <div>
                <h1 className="text-headline-md text-white">Blog/News</h1>
            </div>
            <div>
                <button
                    className="my-4 flex items-center rounded-lg bg-[#FF971D] px-[16px] py-[8px] text-white"
                    onClick={() => navigate('/admin/blogs/add')}>
                    <IoAddCircle className="mr-2" size={24} />
                    Tambah Blog/Berita
                </button>
            </div>
            {/* Tabel Helpers */}
            <div className="rounded-lg bg-[#202022] p-4">
                <span className="text-body-lg text-white">Daftar Employer</span>

                <div className="my-2 justify-between lg:flex">
                    <select
                        value={limit}
                        onChange={handleItemsPerPageChange}
                        className="rounded-lg border border-[#F3B129] bg-[#202022] p-2 text-white">
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                    </select>
                </div>

                <div className="my-4 overflow-x-auto rounded-lg border border-[#515151]">
                    <table className="table w-full space-y-6 rounded-t-lg p-[2px] text-white">
                        <thead>
                            <tr className="border-b border-[#515151] py-2 text-subtitle-sm text-white">
                                <th className="w-[5%] p-3 text-center">No</th>
                                <th className="w-[40%] p-3 text-left">Title</th>
                                <th className="w-[40%] p-3 text-left">
                                    Content
                                </th>
                                <th className="w-[15%] p-3 text-white">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            {blogs.length > 0 ? (
                                blogs.map((blog, index) => (
                                    <tr
                                        key={blog.id}
                                        className="border-t border-[#515151] text-start font-light">
                                        <td className="content-start px-4 py-2 text-center text-white">
                                            {(page - 0) * limit + index + 1}
                                        </td>
                                        <td className="content-start px-4 py-2 text-left text-white">
                                            {blog.title}
                                        </td>
                                        <td className="content-start px-4 py-2 text-left text-white">
                                            {blog.content}
                                        </td>
                                        <td className="px-4 py-2 text-left text-white">
                                            <img src={blog.url} alt="" />
                                        </td>
                                        <td className="p-3">
                                            <div className="flex w-fit items-center rounded-md bg-[#313135] px-[16px] py-[8px]">
                                                <Link
                                                    to={`/admin/blogs/edit/${blog.id}`}
                                                    className="mx-2 text-gray-400 hover:text-gray-100">
                                                    <FaRegEdit
                                                        className="text-base text-green-600"
                                                        size={18}
                                                    />
                                                </Link>
                                                <div className="mx-[8px] h-[18px] w-[2px] bg-[#979797]"></div>
                                                <a
                                                    href={blog.id}
                                                    className="ml-2 text-gray-400 hover:text-gray-100"
                                                    title="Delete"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleDeleteClick(
                                                            blog.id,
                                                        );
                                                    }}>
                                                    <FaRegTrashAlt
                                                        className="text-base text-red-600"
                                                        size={18}
                                                    />
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td
                                        colSpan="7"
                                        className="px-4 py-2 text-center text-white">
                                        {msg || 'Tidak ada data'}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="mt-[32px] flex items-center justify-between">
                    <div className="text-white">
                        Menampilkan {blogs.length} dari {rows} data
                    </div>
                    <ReactPaginate
                        className="flex gap-x-2 rounded-md border border-[#f3b129] p-2"
                        previousLabel="<"
                        nextLabel=">"
                        breakLabel="..."
                        pageCount={pages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={changePage}
                        containerClassName="flex space-x-2"
                        pageClassName="px-4 py-2 rounded-lg cursor-pointer hover:bg-[#F3B129] hover:text-white"
                        previousClassName="px-4 py-2 rounded-lg cursor-pointer"
                        nextClassName="px-4 py-2 rounded-lg cursor-pointer"
                        activeClassName="bg-[#F3B129] text-white"
                        disabledClassName="text-gray-500 cursor-not-allowed"
                    />
                </div>
            </div>
        </div>
    );
}

export default Blog;
