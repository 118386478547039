import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { baseApi } from '../utils/constant';
import Swal from 'sweetalert2'; // Import SweetAlert
import withReactContent from 'sweetalert2-react-content';
import { FaEdit } from 'react-icons/fa'; // Import ikon edit dari react-icons
import foto from '../assets/howit1.jpg';
const MySwal = withReactContent(Swal); // Membuat instance SweetAlert

function Profile() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [profileImage, setProfileImage] = useState(''); // State untuk foto profil
    const [token, setToken] = useState('');
    const [expired, setExpired] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        refreshToken();
        getInfoProfile();
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseApi}/users/token`);
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setExpired(decoded.exp);
        } catch (error) {
            navigate('/login');
        }
    };

    const axiosJwt = axios.create();

    axiosJwt.interceptors.request.use(
        async (config) => {
            const currentDate = new Date();
            if (expired < currentDate.getTime() / 1000) {
                const response = await axios.get(`${baseApi}/users/token`);
                config.headers.Authorization = `Bearer ${response.data.accessToken}`;
                setToken(response.data.accessToken);
                const decoded = jwtDecode(response.data.accessToken);
                setExpired(decoded.exp);
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        },
    );

    const getInfoProfile = async () => {
        try {
            const response = await axiosJwt.get(
                `${baseApi}/users/getInfoUser`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
            setUsername(response.data.username);
            setEmail(response.data.email);
            setProfileImage(response.data.profileImage); // Set foto profil dari response backend
        } catch (error) {
            console.error('Error fetching user info:', error);
            navigate('/login');
        }
    };

    const updatePassword = async (e) => {
        e.preventDefault();

        // Konfirmasi SweetAlert2 sebelum memperbarui password
        const result = await MySwal.fire({
            title: 'Konfirmasi',
            text: 'Apakah Anda yakin ingin memperbarui password?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya, Simpan',
            cancelButtonText: 'Batal',
            reverseButtons: true,
            customClass: {
                popup: 'custom-swal',
            },
            background: 'transparent',
            iconColor: '#B24400',
            toast: true, // Toast untuk pop-up yang tidak muncul di tengah
            position: 'top-end', // Posisi pop-up di pojok kanan atas
        });

        if (result.isConfirmed) {
            try {
                await axiosJwt.put(
                    `${baseApi}/users/updatePassword`,
                    { oldPassword, newPassword, confirmPassword },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    },
                );

                // SweetAlert untuk notifikasi sukses
                MySwal.fire({
                    icon: 'success',
                    title: 'Password berhasil diperbarui!',
                    toast: true,
                    position: 'top-end', // Tetap di posisi pojok kanan atas
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    customClass: {
                        popup: 'custom-swal',
                    },
                    background: 'transparent',
                    iconColor: '#409900',
                });
            } catch (error) {
                if (error.response) {
                    // SweetAlert untuk notifikasi error dari backend
                    MySwal.fire({
                        icon: 'error',
                        title: 'Gagal memperbarui password!',
                        text: error.response.data.message, // Pesan error dari backend
                        toast: true,
                        position: 'top-end', // Tetap di pojok kanan atas
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        customClass: {
                            popup: 'custom-swal',
                        },
                        background: 'transparent',
                        iconColor: '#B24400',
                    });

                    // Menyimpan pesan error di state jika diperlukan
                    setMessage(error.response.data.message);
                }
            }
        }
    };

    // const handleEditProfileImage = () => {
    //     // Logika untuk mengedit foto profil
    //     MySwal.fire({
    //         title: 'Edit Foto Profil',
    //         input: 'file',
    //         inputAttributes: {
    //             accept: 'image/*',
    //             'aria-label': 'Upload foto profil',
    //         },
    //         showCancelButton: true,
    //         confirmButtonText: 'Upload',
    //         cancelButtonText: 'Batal',
    //         background: 'transparent',
    //         customClass: {
    //             popup: 'custom-swal',
    //         },
    //     }).then(async (result) => {
    //         if (result.isConfirmed) {
    //             const file = result.value;
    //             const formData = new FormData();
    //             formData.append('profileImage', file);

    //             try {
    //                 const response = await axios.put(
    //                     `${baseApi}/users/updateProfileImage`,
    //                     formData,
    //                     {
    //                         headers: {
    //                             'Content-Type': 'multipart/form-data',
    //                             Authorization: `Bearer ${token}`,
    //                         },
    //                     },
    //                 );
    //                 setProfileImage(response.data.profileImage); // Update foto profil
    //                 MySwal.fire({
    //                     icon: 'success',
    //                     title: 'Foto profil berhasil diperbarui!',
    //                     toast: true,
    //                     position: 'top-end',
    //                     showConfirmButton: false,
    //                     timer: 3000,
    //                     timerProgressBar: true,
    //                     customClass: {
    //                         popup: 'custom-swal',
    //                     },
    //                     background: 'transparent',
    //                     iconColor: '#409900',
    //                 });
    //             } catch (error) {
    //                 MySwal.fire({
    //                     icon: 'error',
    //                     title: 'Gagal memperbarui foto profil!',
    //                     text: error.response.data.message,
    //                     toast: true,
    //                     position: 'top-end',
    //                     showConfirmButton: false,
    //                     timer: 3000,
    //                     timerProgressBar: true,
    //                     customClass: {
    //                         popup: 'custom-swal',
    //                     },
    //                     background: 'transparent',
    //                     iconColor: '#B24400',
    //                 });
    //             }
    //         }
    //     });
    // };

    return (
        <div className="mt-[70px] p-[32px]">
            <div className="flex flex-col items-center">
                <h1 className="mb-8 text-headline-md text-white">Profile</h1>

                {/* Foto Profil dengan tombol Edit */}
                <div className="relative mb-6">
                    <img
                        src={foto}
                        alt="Profile"
                        className="h-32 w-32 rounded-full object-cover"
                    />
                    {/* <button
                        onClick={handleEditProfileImage}
                        className="absolute bottom-0 right-0 rounded-full border-2 border-blue-500 bg-white p-2 hover:border-blue-700">
                        <FaEdit className="text-blue-500" />
                    </button> */}
                </div>

                {/* Info Pengguna */}
                <div className="w-full max-w-md rounded-lg bg-[#202022] p-6 text-center text-white">
                    <h2 className="mb-2 text-body-lg">Username: {username}</h2>
                    <h3 className="mb-6 text-body-lg">Email: {email}</h3>

                    {/* Form untuk update password */}
                    <div className="text-left">
                        <h2 className="mb-2 text-body-lg text-white">
                            Update Password
                        </h2>
                        <input
                            type="password"
                            placeholder="Masukkan password lama"
                            className="mb-2 w-full rounded-lg bg-[#303033] p-2 text-white"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                        />
                        <input
                            type="password"
                            placeholder="Masukkan password baru"
                            className="mb-2 w-full rounded-lg bg-[#303033] p-2 text-white"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <input
                            type="password"
                            placeholder="Konfirmasi password baru"
                            className="mb-4 w-full rounded-lg bg-[#303033] p-2 text-white"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <button
                            onClick={updatePassword}
                            className="w-full rounded-lg bg-[#1AA053] py-2 text-white">
                            Update Password
                        </button>

                        <button
                            onClick={() => navigate('/admin/dashboard')}
                            className="my-2 w-full rounded-lg bg-[#FF2E2E] py-2 text-white">
                            Kembali
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;
